import React, { useState, useEffect } from "react";
// import { addAlertValidationSchema } from 'store/validations/alertValidation';
import { useForm, Controller } from "react-hook-form";
import { addEventValidationSchema } from "store/validations/alertValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import NumberFormat from "react-number-format";
import Select from "react-select";
import {
  getAllRequirmentData,
  getCompanies,
  getCompanyById,
} from "store/services/company.service";
import { addStatuaryEvent } from "store/services/company.service";
import { ToastContainer, toast } from "react-toastify";
import { getShareHoldersByCompany } from "store/services/shareholder.service";
import { darkStyle, errorStyles } from "components/defaultStyles";
import { company_code_setter } from "store/services/dropdown.service";
import moment from "moment";

export const AddStatuaryEvent = ({ setAddEvent }) => {
  const tempRequirementDetails = JSON.parse(
    sessionStorage.getItem("tempRequirementDetails")
  );
  const selectedDateForEvent = JSON.parse(
    sessionStorage.getItem("SelectedDateForEvent")
  );

  const requirementForDate = JSON.parse(
    sessionStorage.getItem("requirementForDates")
  )

  const [baseCompany, setBaseCompany] = useState(
    sessionStorage.getItem("company_code")
  );

  const [manualStatuaryData, setManualStatutoryData] = useState([]);
  const email = sessionStorage.getItem("email");
  // const [selectedCompany, setSelectedCompany] = useState("");
  const [companies_dropdown, setCompanies_dropdown] = useState([]);
  const [companies_data_loading, setCompanies_data_loading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requirment, setRequirment] = useState("");
  const baseEmail = sessionStorage.getItem("email") || "";
  const [shareholder_dropDown, setshareholder_dropDown] = useState([]);
  const [sharehoerData, setShareholderData] = useState("");
  const [shareHolderLoading, setShareHolderLoading] = useState(false);
  const [selectedshareHolder, setSelectedShareholder] = useState("");
  const [durationLoading, setDurationLoading] = useState(false);
  const [gotEndDate, setGotEndDate] = useState(false);

  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
    clearErrors,
    control,
    trigger,
    setValue,
  } = useForm({ resolver: yupResolver(addEventValidationSchema) });

  useEffect(() => {
    if (tempRequirementDetails?._id) {
      let item = tempRequirementDetails.selectedCompanyData;
      let label = `${item.company_name}`;
      setValue("request_code", {
        label: `${tempRequirementDetails?.code} - ${tempRequirementDetails?.title}`,
        value: `${tempRequirementDetails?.code} - ${tempRequirementDetails?.title}`,
      });
      setValue(
        "title",
        `${tempRequirementDetails?.code} - ${tempRequirementDetails?.title}`
      );
      setValue("company_code", { label: label, value: item.code });
    }
  }, [companies_dropdown]);

  const getaALLRequirment = async (pagenum) => {
    // setLoadingRequirment(true);
    try {
      const response = await getAllRequirmentData(baseEmail);
      if (response.data.status === 200) {
        const options = response?.data?.data?.map((item) => {
          let label = `${item?.title}`;
          let value = `${item?.code} - ${item?.title}`;
          return { label: label, value: value, secp_form: item?.secp_form };
        });

        setManualStatutoryData(options);
      }
    } catch (error) { }
  };

  useEffect(() => {
    const handleStorageChange = () => {
      setBaseCompany(sessionStorage.getItem("company_code"));
      return;
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    getaALLRequirment();

    // if (selectedDateForEvent) {
    // setValue("start_date", selectedDateForEvent.date.split('T')[0])
    // setValue("deadline_date", selectedDateForEvent.date.split('T')[0])
    // setValue("company_code", {
    // label: selectedDateForEvent.company,
    // value: selectedDateForEvent.company,
    // });
    // }
  }, [baseCompany]);

  // useEffect(() => {
  //   setValue("voting_required", false)

  // }, [watch("request_code")?.value])

  // useEffect(() => {

  //   if (watch("start_date")) {
  //     let tesDat = new Date(watch("start_date"))
  //     if (tesDat.getDay() == 0 || tesDat.getDay() == 6) {
  //       toast.error("Event can not start on weekend")
  //       setValue("start_date", "")
  //     }
  //   }

  // }, [watch("start_date")])
  // useEffect(() => {

  //   if (watch("deadline_date")) {
  //     let tesDat = new Date(watch("deadline_date"))
  //     if (tesDat.getDay() == 0 || tesDat.getDay() == 6) {
  //       toast.error("Event can not end on weekend")
  //       setValue("deadline_date", "")
  //     }
  //   }

  // }, [watch("deadline_date")])

  useEffect(() => {
    if (watch("start_date") && !gotEndDate) {
      let tempDate = new Date(watch("start_date"));
      tempDate.setHours(tempDate.getHours() + 1);
      const year = tempDate.getFullYear();
      const month = String(tempDate.getMonth() + 1).padStart(2, "0");
      const day = String(tempDate.getDate()).padStart(2, "0");
      const hours = String(tempDate.getHours()).padStart(2, "0");
      const minutes = String(tempDate.getMinutes()).padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
      setValue("deadline_date", formattedDate);
    }

    if (watch("start_date")) {
      setValue("book_closing_to", moment(new Date(watch("start_date")).toString().slice(0, -1)).format("YYYY-MM-DD"))
    }
  }, [watch("start_date"), gotEndDate]);


  useEffect(() => {
    if (watch("book_closing_to")) {
      setValue("book_closure", true);
      setValue("book_closing_from", moment(watch("book_closing_to")).add(-7, "days").format("YYYY-MM-DD"))

    }
  }, [watch("book_closing_to")])



  useEffect(() => {
    if (requirementForDate) {
      setValue("request_code", { label: `${requirementForDate?.item?.title}`, value: `${requirementForDate?.item?.code} - ${requirementForDate?.item?.title}` })
      setValue("title", `${requirementForDate?.item?.title}`)
      if (requirementForDate?.start === requirementForDate?.end) {
        let tempDate = new Date(requirementForDate?.start.slice(0, -1));
        // const year = tempDate.getFullYear();
        // const month = String(tempDate.getMonth() + 1).padStart(2, "0");
        // const day = String(tempDate.getDate()).padStart(2, "0");
        // const hours = String(tempDate.getHours()).padStart(2, "0");
        // const minutes = String(tempDate.getMinutes()).padStart(2, "0");

        // const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;

        const formattedDate = moment.utc(tempDate).add(-1, "days").format('YYYY-MM-DDTHH:mm');


        setValue("start_date", formattedDate)
      } else if (requirementForDate?.start && !requirementForDate?.end) {
        let tempDate = new Date(requirementForDate?.start.slice(0, -1));
        // const year = tempDate.getFullYear();
        // const month = String(tempDate.getMonth() + 1).padStart(2, "0");
        // const day = String(tempDate.getDate()).padStart(2, "0");
        // const hours = String(tempDate.getHours()).padStart(2, "0");
        // const minutes = String(tempDate.getMinutes()).padStart(2, "0");

        // const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;

        // const formattedDate = moment(tempDate).format('YYYY-MM-DDTHH:mm');
        const formattedDate = moment(tempDate).format('YYYY-MM-DDTHH:mm');


        setValue("start_date", formattedDate)
      } else if (requirementForDate?.start && !requirementForDate?.end) {
        setGotEndDate(true);

        let tempDate = new Date(requirementForDate?.start.slice(0, -1));
        const formattedDate = moment(tempDate).format('YYYY-MM-DDTHH:mm');

        let tempEndDate = new Date(requirementForDate?.end.slice(0, -1));
        const formattedEndDate = moment(tempEndDate).format('YYYY-MM-DDTHH:mm');

        setValue("start_date", formattedDate)
        setValue("deadline_date", formattedEndDate)

      } else if (requirementForDate?.start) {
        let tempDate = new Date(requirementForDate?.start.slice(0, -1));
        // const year = tempDate.getFullYear();
        // const month = String(tempDate.getMonth() + 1).padStart(2, "0");
        // const day = String(tempDate.getDate()).padStart(2, "0");
        // const hours = String(tempDate.getHours()).padStart(2, "0");
        // const minutes = String(tempDate.getMinutes()).padStart(2, "0");

        // const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;

        // const formattedDate = moment(tempDate).format('YYYY-MM-DDTHH:mm');
        const formattedDate = moment(tempDate).format('YYYY-MM-DDTHH:mm');


        setValue("start_date", formattedDate)
      }
    }

    if (selectedDateForEvent) {
      // let tempDate = new Date(selectedDateForEvent?.date);
      let tempDate = new Date(selectedDateForEvent?.date.slice(0, -1));

      // const year = tempDate.getFullYear();
      // const month = String(tempDate.getMonth() + 1).padStart(2, "0");
      // const day = String(tempDate.getDate()).padStart(2, "0");
      // const hours = String(tempDate.getHours()).padStart(2, "0");
      // const minutes = String(tempDate.getMinutes()).padStart(2, "0");

      // const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;

      setValue("start_date", moment(tempDate).format('YYYY-MM-DDTHH:mm'))
    }

    return () => {
      sessionStorage.removeItem("tempRequirementDetails")


      sessionStorage.removeItem("SelectedDateForEvent")



      sessionStorage.removeItem("requirementForDates")

    }


  }, [])



  const [allCompaniesData, setAllCompaniesData] = useState([]);
  const [chairmanUsersOptions, setChairmanUsersOptions] = useState([]);

  const handleAlertMessage = async (data) => {
    // await trigger();
    // if (!requirment) {
    //   toast.error('Enter Request Code')
    //   return
    // }
    // if (!selectedCompany) {
    //   toast.error('Enter Company Code')
    //   return
    // }

    // watch("voting_required")

    try {
      setLoading(true);

      const response = await addStatuaryEvent(
        email,
        // data?.company_code?.value,
        baseCompany,
        data?.request_code?.value,
        data?.title || "",
        data?.start_date || "",
        data?.deadline_date || "",
        data?.reminder_days || "",
        data?.action_date || "",
        data?.action_by_text || "",
        data?.previous_action_date || "",
        data?.status || "",
        data?.comment || "",
        data?.closed || "",
        data?.chairman || "",
        data?.event_location || "",
        // data?.effect_from || "",
        // data?.last_date || "",
        // data?.agm_date || "",
        // data?.agm_end_date || "",
        // data?.application_from || "",
        // data?.application_to || "",
        // data?.authorization_from || "",
        // data?.authorization_to || "",
        // data?.election_from || "",
        // data?.election_to || "",
        // data?.pol_date || "",
        // data?.postal_ballot_last_date || "",
        // data?.agenda_from || "",
        // data?.agenda_to || "",
        // data?.meeting_start_date || "",
        // data?.meeting_end_date || "",
        data?.online_conference || "",
        // data?.voting_required || false,
        data?.book_closing_from || "",
        data?.book_closing_to || "",

        // new fields
        // data?.attendance_required,
        //  data?.eod_voting,
        //  data?.eod_evoting,
        //  data?.sr_voting,
        //  data?.sr_evoting,
        data?.book_closure,
        data?.meeting_required
      );

      if (response.data.status === 200) {
        setTimeout(() => {
          setLoading(false);
          window.location.reload();
          // getAllCompanies();
          toast.success(`${response.data.message}`);
          setAddEvent(false);
        }, 2000);
      } else {
        setLoading(false);
        toast.error(`${response.data.message}`);
      }
    } catch (error) {
      setLoading(false);
      !!error?.response?.data?.message
        ? toast.error(error?.response?.data?.message)
        : toast.error("Event Not Added");
    }
  };

  const getShareholderByCompany = async () => {
    try {
      setshareholder_dropDown([]);
      setShareholderData([]);
      setShareHolderLoading(true);
      const response = await getShareHoldersByCompany(
        email,
        baseCompany
      );
      if (response.status == 200) {
        const options = response.data.data?.map((item) => {
          const physicalShare = Number(item?.physical_shares)
            ? Number(item?.physical_shares)
            : 0;
          const electronicShare = Number(item?.electronic_shares)
            ? Number(item?.electronic_shares)
            : 0;
          const physical_electroninc_shares = (
            physicalShare + electronicShare
          )?.toString();
          const shareholder_id = item?.folio_number;
          let label = `${shareholder_id} - ${item?.shareholder_name}`;
          return {
            label: label,
            value: shareholder_id,
            name: item?.shareholder_name,
            NoofShare: physical_electroninc_shares,
          };
        });
        setshareholder_dropDown(options);
        setShareholderData(response.data.data);
        setShareHolderLoading(false);
      }
    } catch (error) {
      if (error.response != undefined) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  const getAllCompanies = async () => {
    setCompanies_data_loading(true);
    try {
      // debugger
      const response = await getCompanyById(baseEmail);
      if (response?.data?.status === 200) {
        // response.data.data = response.data.data.filter(
        //   (item) => item?.active === "Y"
        // );
        // const parents = response.data.data;
        setAllCompaniesData(response.data.data);
        let selectedCompany = response.data.data;
        if (selectedCompany.governance) {
          selectedCompany.governance = JSON.parse(selectedCompany.governance);
        }

        let chairmanDropdown = selectedCompany.governance.map((item) => {
          return { label: item.name, value: item.name };
        });

        setChairmanUsersOptions(chairmanDropdown);
        let isChairman = selectedCompany.governance.find(
          (item) => item.role === "Chairman"
        );
        if (isChairman) {
          setValue(
            "chairman",
            chairmanDropdown.find((item) => item.value === isChairman.name)
              ?.value
          );
        }
        // const companies_dropdowns = response.data.data.map((item) => {
        //   let label = `${item.company_name}`;
        //   return { label: label, value: item.code };
        // });
        // setCompanies_dropdown(companies_dropdowns);
        setCompanies_data_loading(false);
      }
    } catch (error) {
      setCompanies_data_loading(false);
      setCompanies_dropdown([]);
    }
  };

  useEffect(() => {
    if (baseCompany) {
      setValue("chairman", "");

      getAllCompanies();
      getShareholderByCompany();
    } else {
      toast.error("Select company in header");
      setAddEvent(false);
    }
  }, [baseCompany]);

  // useEffect(() => {
  //   setValue("chairman", "");
  //   // if (watch("company_code")?.value && allCompaniesData.length) {
  //   if (baseCompany?.value) {
  //     // getShareholderByCompany(watch("company_code")?.value);
  //     getShareholderByCompany();
  //     // let selectedCompany = allCompaniesData.find(
  //     //   (company) => company.code == watch("company_code")?.value
  //     // );
  //     let selectedCompany = allCompaniesData;
  //     // setValue("agm_date", selectedCompany?.agm_date)
  //     // setValue("agm_end_date", selectedCompany?.agm_date)
  //     if (selectedCompany.governance) {
  //       selectedCompany.governance = JSON.parse(selectedCompany.governance);
  //     }

  //     let chairmanDropdown = selectedCompany.governance.map((item) => {
  //       return { label: item.name, value: item.name };
  //     });

  //     setChairmanUsersOptions(chairmanDropdown);
  //     let isChairman = selectedCompany.governance.find(
  //       (item) => item.role === "Chairman"
  //     );
  //     if (isChairman) {
  //       setValue(
  //         "chairman",
  //         chairmanDropdown.find((item) => item.value === isChairman.name)?.value
  //       );
  //     }
  //   }
  // }, [watch("company_code")?.value, allCompaniesData]);

  useEffect(() => {
    setValue("reminder_days", 10);
  }, []);

  const appliedStyles = {
    control: (base, state) => ({
      ...base,
      border: "1px solid red",
    }),
  };

  const [screen, setScreen] = useState();

  useEffect(() => {
    const handleResize = () => {
      setScreen(window?.outerWidth);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit(handleAlertMessage)}>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="card ">
              <div className="card-header b-t-primary">
                <h5>Event</h5>
              </div>
              <div className="card-body">
                {/* <div className="form-group mt-3">
                  <label htmlFor="company_code">Company</label>
                  <Controller
                    name="company_code"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={companies_dropdown}
                        isLoading={companies_data_loading === true}
                        id="company_code"
                        placeholder="Enter Company"
                        // onChange={(selected) => {
                        //   if (selected?.value) setSelectedCompany(selected?.value);
                        //   else setSelectedCompany('');
                        //   clearErrors('company_code', { shouldValidate: false });
                        // }}
                        isClearable={true}
                        styles={errors.company_code && appliedStyles}
                      />
                    )}
                  />
                  <small className="text-danger">
                    {errors.company_code?.message}
                  </small>
                </div> */}

                {/* <div className="form-group mt-3">
                  <label>Event Chairman</label>
                  <Controller
                    name="chairman"
                    render={({ onChange, field, value }) => (
                      <Select
                        {...field}
                        options={chairmanUsersOptions}
                        // isLoading={true}
                        id="chairman"
                        name="chairman"
                        // value={chairmanUsersOptions.find(c => c.value === value)}
                        value={{ label: watch("chairman"), value: watch("chairman") }}
                        onChange={val => setValue("chairman", val?.value)}
                        placeholder={"Enter Chairman"}
                        // {...register("chairman")}
                        // onChange={(selected) => {
                        //   if (selected?.label) {
                        //     setRequirment(selected?.label)
                        //     clearErrors('chairman', { shouldValidate: false });
                        //   } else {
                        //     setRequirment('')
                        //   }
                        // }}
                        isClearable={true}
                        styles={errors.chairman && !watch("chairman") && appliedStyles}
                      />
                    )}
                    control={control}
                  />
                  <small className="text-danger">
                    {errors.chairman && !watch("chairman") && errors.chairman?.message}
                  </small>
                </div> */}

                {/* {false && <div className="form-group mt-3">
                  <label htmlFor="chairman">Event Chairman</label>
                  <input
                    name="chairman"
                    className={`form-control ${errors.chairman && "border border-danger"
                      }`}
                    type="text"
                    placeholder="Enter Chairman"
                    {...register("chairman")}
                  />
                  <small className="text-danger">
                    {errors.chairman?.message}
                  </small> */}
                {/* <Controller
                    name="folio_no"
                    render={({ field }) => (
                      <Select
                        {...field}
                        isLoading={shareHolderLoading}
                        options={shareholder_dropDown}
                        id="folio_no"
                        placeholder={'Select Event Chairman'}
                        onChange={(selected) => {
                          if (selected?.value) setSelectedShareholder(selected?.value);
                          else setSelectedShareholder("");
                        }}
                        isClearable={true}
                        styles={errors.folio_no && appliedStyles}

                      />
                    )}
                    control={control}
                  /> */}
                {/* </div>} */}
                <div className="form-group mt-3">
                  <label>Requirement</label>
                  <Controller
                    name="request_code"
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={manualStatuaryData}
                        // isLoading={true}
                        id="request_code"
                        placeholder={"Enter Requirement"}
                        // onChange={(selected) => {
                        //   if (selected?.label) {
                        //     setRequirment(selected?.label)
                        //     clearErrors('request_code', { shouldValidate: false });
                        //   } else {
                        //     setRequirment('')
                        //   }
                        // }}
                        isClearable={true}
                        styles={errors.request_code && appliedStyles}
                      />
                    )}
                    control={control}
                  />
                  <small className="text-danger">
                    {errors.request_code?.message}
                  </small>
                </div>
                <div className="form-group mt-3">
                  <label>Title</label>
                  <input
                    name="title"
                    className={`form-control ${errors.title && "border border-danger"
                      }`}
                    type="text"
                    placeholder="Enter Title"
                    {...register("title")}
                  />
                  <small className="text-danger">{errors.title?.message}</small>
                </div>

                {/* <div className="form-group mt-3">
                  <label>Event Location</label>
                  <input
                    name="event_location"
                    className={`form-control ${errors.event_location && "border border-danger"
                      }`}
                    type="text"
                    placeholder="Enter Event Location"
                    {...register("event_location")}
                  />
                  <small className="text-danger">
                    {errors.event_location?.message}
                  </small>
                </div> */}

                <div className="form-group mt-3">
                  <label>Comment </label>
                  <input
                    name="comment"
                    className={`form-control ${errors.comment && "border border-danger"
                      }`}
                    type="text"
                    placeholder="Enter Comment"
                    {...register("comment")}
                  />
                  <small className="text-danger">
                    {errors.comment?.message}
                  </small>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="card ">
              <div className="card-header b-t-primary">
                <h5>Dates</h5>
              </div>

              <div className="card-body">
                <div className="form-group mt-3">
                  {/* <label htmlFor="agm_date">AGM Date and Start Time</label> */}
                  {/* <label htmlFor="start_date">Meeting Duration</label> */}
                  <label htmlFor="start_date">Event Duration</label>

                  <div className="d-flex row px-3">
                    <div className={`${screen <= 767 ? "mb-3" : ""} col-md-6 col-sm-12 p-0`}>
                      <input
                        className={`form-control ${errors.start_date && "border border-danger"
                          }`}
                        name="start_date"
                        type="datetime-local"
                        {...register("start_date")}
                      // onChange={(e) => e.target.blur()}
                      />
                    </div>
                    <div className=" col-md-6 col-sm-12 p-0">
                      <input
                        className={`form-control ${errors.deadline_date &&
                          "border border-danger col-md-6 col-sm-12"
                          }`}
                        name="deadline_date"
                        type="datetime-local"
                        {...register("deadline_date")}
                      // onChange={(e) => e.target.blur()}
                      />
                    </div>
                  </div>

                  <small className="text-danger">
                    {errors.start_date?.message}
                  </small>
                  <small className="text-danger">
                    {errors.deadline_date?.message}
                  </small>
                </div>

                <div className="form-group mt-3">
                  <label>Reminder Before</label>

                  <Controller
                    name="reminder_days"
                    render={({ field }) => (
                      <NumberFormat
                        {...field}
                        className={`form-control ${errors.reminder_days && "border border-danger"
                          }`}
                        id="reminder_days"
                        allowNegative={false}
                        decimalScale={0}
                        defaultValue={10}
                        placeholder="Enter Number of Days"
                      />
                    )}
                    control={control}
                  />

                  <small className="text-danger">
                    {errors.reminder_days?.message}
                  </small>
                </div>

                <div className="form-group mt-3">
                  <div className="d-flex justify-content-start">
                    <div>
                      <input
                        className={
                          "form-check-input" +
                          `${errors.book_closure && " border border-danger"}`
                        }
                        type="checkbox"
                        id="book_closure"
                        name="book_closure"
                        checked={watch("book_closure")}
                        {...register("book_closure")}
                      />
                      <label
                        className="form-check-label ml-2 "
                        for="book_closure"
                      >
                        Book Closure Period
                      </label>
                      <small className="text-danger">
                        {errors.book_closure?.message}
                      </small>
                    </div>
                  </div>
                </div>

                {watch("book_closure") && (
                  <div className="form-group mt-3  ">
                    <div className="d-flex  row px-3">
                      <div className={`${screen <= 767 ? "mb-3" : ""} col-md-6 col-sm-12 p-0`}>
                        <input
                          className={`form-control ${errors.book_closing_from && "border border-danger"
                            }`}
                          name="book_closing_from"
                          type="date"
                          {...register("book_closing_from")}
                        />
                        <small className="text-danger">
                          {errors.book_closing_from?.message}
                        </small>
                      </div>
                      <div className=" col-md-6 col-sm-12 p-0">
                        <input
                          className={`form-control ${errors.book_closing_to && "border border-danger"
                            }`}
                          name="book_closing_to"
                          type="date"
                          {...register("book_closing_to")}
                        />
                        <small className="text-danger">
                          {errors.book_closing_to?.message}
                        </small>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="card ">
              <div className="card-header b-t-primary">
                <div className="d-flex">
                  <input
                    className={
                      "form-check-input" +
                      `${errors.meeting_required && " border border-danger"}`
                    }
                    style={{ marginRight: "5px" }}
                    type="checkbox"
                    id="meeting_required"
                    name="meeting_required"
                    checked={watch("meeting_required")}
                    {...register("meeting_required")}
                  />
                  <h5>Meeting</h5>
                </div>
              </div>

              {watch("meeting_required") && (
                <div className="card-body">
                  <div className="form-group mt-3">
                    <label>Meeting Chairman</label>
                    <Controller
                      name="chairman"
                      render={({ onChange, field, value }) => (
                        <Select
                          {...field}
                          options={chairmanUsersOptions}
                          id="chairman"
                          name="chairman"
                          value={{
                            label: watch("chairman"),
                            value: watch("chairman"),
                          }}
                          onChange={(val) => setValue("chairman", val?.value)}
                          placeholder={"Enter Chairman"}
                          isClearable={true}
                          styles={
                            errors.chairman &&
                            !watch("chairman") &&
                            appliedStyles
                          }
                        />
                      )}
                      control={control}
                    />
                    <small className="text-danger">
                      {errors.chairman &&
                        !watch("chairman") &&
                        errors.chairman?.message}
                    </small>
                  </div>

                  <div className="form-group mt-3">
                    <label>Meeting Location</label>
                    <input
                      name="event_location"
                      className={`form-control ${errors.event_location && "border border-danger"
                        }`}
                      type="text"
                      placeholder="Enter Event Location"
                      {...register("event_location")}
                    />
                    <small className="text-danger">
                      {errors.event_location?.message}
                    </small>
                  </div>

                  <div className="form-group mt-3">
                    <label htmlFor="term">Meeting Online link</label>

                    <input
                      name="online_conference"
                      className={`form-control ${errors.online_conference && "border border-danger"
                        }`}
                      type="text"
                      placeholder="Meeting Online Link"
                      {...register("online_conference")}
                    />
                    <small className="text-danger">
                      {errors.online_conference?.message}
                    </small>
                  </div>

                  {/* {watch("request_code")?.value && (/(^|[^0-9])(?:26|29)([^0-9]|$)/.test(watch("request_code")?.value) || /(^|[^0-9])(?:26|29)([^0-9]|$)/.test(watch("request_code")?.secp_form)) &&  */}
                  {/* <div className="form-group mt-3">
                  <input
                    className={"form-check-input" + `${errors.attendance_required && " border border-danger"}`}
                    type="checkbox"
                    id="attendance_required"
                    name="attendance_required"
                    checked={watch("attendance_required")}
                    {...register("attendance_required")}
                  />
                  <label className="form-check-label ml-2 " for="attendance_required">Attendance required</label>
                  <small className="text-danger">
                    {errors.attendance_required?.message}
                  </small>
                </div> */}

                  {/* } */}
                </div>
              )}
            </div>
          </div>

          {/* {watch("voting_required") && watch("request_code")?.value.includes(29) && <div className="col-sm-12 col-md-6 col-xl-4"> */}
          {/* <div className="col-sm-12 col-md-6 col-xl-4">
            <div className="card ">
              <div className="card-header b-t-success">
                <div className="d-flex">
                <input
                    className={"form-check-input" + `${errors.eod_voting && " border border-danger"}`}
                    type="checkbox"
                    id="eod_voting"
                    name="eod_voting"
                    checked={watch("eod_voting")}
                    {...register("eod_voting")}
                    style={{marginRight: "5px"}}
                  />
                <h5>Election of Directors</h5>
                </div>
              </div>
             {watch("eod_voting") && 
             <div className="card-body"> */}

          {/* <div className="form-group mt-3">
                  <label htmlFor="agm_end_date">AGM Date and End Time </label>

                
                </div> */}

          {/* <div className="form-group mt-3">
                  <label htmlFor="application_from">Candidate Application Duration</label>
                  <div className="d-flex">

                    <div className="col-md-6 col-sm-12 p-0">
                  <input
                    className={`form-control ${errors.application_from && "border border-danger"
                      }`}
                    name="application_from"
                    type="date"
                    {...register("application_from")}
                  />
                  <small className="text-danger">
                    {errors.application_from?.message}
                  </small>
                  </div>
                  <div className=" col-md-6 col-sm-12 p-0">
                  <input
                    className={`form-control ${errors.application_to && "border border-danger"
                      }`}
                    name="application_to"
                    type="date"
                    {...register("application_to")}
                  />
                  <small className="text-danger">
                    {errors.application_to?.message}
                  </small>
                </div>
                </div>
                </div>

                <div className="form-group mt-3">
                  <div className="d-flex justify-content-between">

                
                  <div>
                  <input
                    className={"form-check-input" + `${errors.eod_evoting && " border border-danger"}`}
                    type="checkbox"
                    id="eod_evoting"
                    name="eod_evoting"
                    checked={watch("eod_evoting")}
                    {...register("eod_evoting")}
                  />
                  <label className="form-check-label ml-2 " for="eod_evoting">EVoting</label>
                  <small className="text-danger">
                    {errors.eod_evoting?.message}
                  </small>
                  </div>
                  </div>
                </div>

               {watch("eod_evoting") &&
               <div className="form-group mt-3">
                  <div className="d-flex">

                  <div className="col-md-6 col-sm-12 p-0">
                  <input
                    className={`form-control ${false && "border border-danger"
                      }`}
                    name="election_from"
                    type="datetime-local"
                    {...register("election_from")}
                    onChange={(e) => {
                      // setElectionFromError(false);
                      e.target.blur();
                    }}
                  />
                    </div>
                    <div className=" col-md-6 col-sm-12 p-0">
                  <input
                    className={`form-control ${false && "border border-danger"
                      }`}
                    name="election_to"
                    type="datetime-local"
                    {...register("election_to")}
                    onChange={(e) => {
                      // setElectionToError(false);
                      e.target.blur();
                    }}
                  />
                </div>
                </div>
                </div>}

                <div className="form-group mt-3">
                  <label htmlFor="last_date">Last Date of Current Board</label>

                  <input
                    className={`form-control ${false && "border border-danger"
                      }`}
                    name="last_date"
                    type="date"
                    {...register("last_date")}
                    onChange={() => {
                      // setLastDateError(false);
                    }}
                  />
                </div>

                <div className="form-group mt-3">
                  <label htmlFor="effect_from">New Board Effective Date</label>

                  <input
                    className={`form-control ${errors.effect_from && "border border-danger"
                      }`}
                    name="effect_from"
                    type="date"
                    {...register("effect_from")}
                  />
                  <small className="text-danger">
                    {errors.effect_from?.message}
                  </small>
                </div> */}

          {/* <div className="form-group mt-3">
                  <label htmlFor="pol_date">Pol Date </label>

                  <input
                    className={`form-control ${errors.pol_date && "border border-danger"
                      }`}
                    name="pol_date"
                    type="date"
                    {...register("pol_date")}
                  />
                  <small className="text-danger">
                    {errors.pol_date?.message}
                  </small>
                </div> */}

          {/* <div className="form-group mt-3">
                  <label htmlFor="postal_ballot_last_date">
                    Postal Ballot Last Date
                  </label>

                  <input
                    className={`form-control ${errors.postal_ballot_last_date && "border border-danger"
                      }`}
                    name="postal_ballot_last_date"
                    type="date"
                    {...register("postal_ballot_last_date")}
                  />
                  <small className="text-danger">
                    {errors.postal_ballot_last_date?.message}
                  </small>
                </div>

                <div className="form-group mt-3">
                  <label htmlFor="authorization_from">
                    Proxy Submission
                  </label>
                  <div className="d-flex">

<div className="col-md-6 col-sm-12 p-0">
                  <input
                    className={`form-control ${errors.authorization_from && "border border-danger"
                      }`}
                    name="authorization_from"
                    type="date"
                    {...register("authorization_from")}
                  />
                  <small className="text-danger">
                    {errors.authorization_from?.message}
                  </small>
                  </div>
                  <div className=" col-md-6 col-sm-12 p-0">

                  <input
                    className={`form-control ${errors.authorization_to && "border border-danger"
                      }`}
                    name="authorization_to"
                    type="date"
                    {...register("authorization_to")}
                  />
                  <small className="text-danger">
                    {errors.authorization_to?.message}
                  </small>
                </div>
                </div>
                </div>
              </div>} */}
          {/* </div>
          </div> */}

          {/* {watch("voting_required") && watch("request_code")?.value.includes(26) && <div className="col-sm-12 col-md-6 col-xl-4"> */}
          {/* <div className="col-sm-12 col-md-6 col-xl-4">
            <div className="card ">
              <div className="card-header b-t-success">
                <div className="d-flex">
                <input
                      className={"form-check-input" + `${errors.sr_voting && " border border-danger"}`}
                      type="checkbox"
                      id="sr_voting"
                      name="sr_voting"
                      checked={watch("sr_voting")}
                      {...register("sr_voting")}
                      style={{marginRight: "5px"}}
                    />
                <h5>Special Resolution</h5>

                </div>
              </div>
             {watch("sr_voting") && 
             <div className="card-body">
              <div className="form-group mt-3">
                  <div className="d-flex justify-content-between">
                    <div>
                    <input
                      className={"form-check-input" + `${errors.sr_evoting && " border border-danger"}`}
                      type="checkbox"
                      id="sr_evoting"
                      name="sr_evoting"
                      checked={watch("sr_evoting")}
                      {...register("sr_evoting")}
                    />
                    <label className="form-check-label ml-2 " for="sr_evoting">EVoting {" "}</label>
                    <small className="text-danger">
                      {errors.sr_evoting?.message}
                    </small>
                    </div>

                  </div>
                  </div>


               {watch("sr_evoting") && 
              <div className="d-flex">

              <div className="col-md-6 col-sm-12 p-0">
                  <input
                    className={`form-control ${false && "border border-danger"
                      }`}
                    name="agenda_from"
                    type="datetime-local"
                    {...register("agenda_from")}
                 
                  />
                  </div>
                  <div className=" col-md-6 col-sm-12 p-0">
                  <input
                    className={`form-control ${false && "border border-danger"
                      }`}
                    name="agenda_to"
                    type="datetime-local"
                    {...register("agenda_to")}
                    />
                
                </div>
              </div>
              } */}

          {/* <div className="form-group mt-3">
                  <label htmlFor="agm_date">Meeting Date and Start Time</label>

                  <input
                    className={`form-control ${errors.agm_date && "border border-danger"
                      }`}
                    name="agm_date"
                    type="datetime-local"
                    {...register("agm_date")}
                  />
                  <small className="text-danger">{errors.agm_date?.message}</small>
                </div> */}

          {/* <div className="form-group mt-3">
                  <label htmlFor="agm_end_date">Meeting Date and End Time </label>

                  <input
                    className={`form-control ${errors.agm_end_date && "border border-danger"
                      }`}
                    name="agm_end_date"
                    type="datetime-local"
                    {...register("agm_end_date")}
                  />
                  <small className="text-danger">{errors.agm_end_date?.message}</small>
                </div> */}
          {/* </div>}
            </div>
          </div> */}

          {/* <div className="col-sm-12 col-md-6 col-xl-4">
            <div className="card ">
              <div className="card-header b-t-success">
                <h5>Dates</h5>
              </div>
              <div className="card-body"> */}

          {/* <div className="form-group mt-3 ">
                  <label>Start Date</label>
                  <input
                    className={`form-control ${errors.start_date && "border border-danger"
                      }`}
                    name="start_date"
                    type="date"
                    value={watch("start_date")}
                    {...register("start_date")}
                  />
                  <small className="text-danger">
                    {errors.start_date?.message}
                  </small>
                </div> */}

          {/* <div className="form-group mt-3  ">
                  <label>Deadline Date</label>
                  <input
                    className={`form-control ${errors.deadline_date && "border border-danger"
                      }`}
                    name="deadline_date"
                    value={watch("deadline_date")}
                    type="date"
                    {...register("deadline_date")}
                  />
                  <small className="text-danger">
                    {errors.deadline_date?.message}
                  </small>
                </div> */}

          {/* <div className="form-group mt-3  ">
                  <label htmlFor="closed">Closed</label>
                  <select
                    name="closed"
                    className={`form-control ${errors.closed && "border border-danger"
                      }`}
                    {...register("closed")}
                  >
                    <option value="">Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                  <small className="text-danger">
                    {errors.closed?.message}
                  </small>
                </div> */}
          {/* </div>
            </div>
          </div> */}

          {/* <div className="col-sm-12 col-md-6 col-xl-4">
            <div className="card ">
              <div className="card-header b-t-success">
                <h5>Action</h5>
              </div>
              <div className="card-body">
                <div className="form-group mt-3 ">
                  <label>Status </label>
                  <input
                    name="status"
                    className={`form-control ${errors.status && "border border-danger"
                      }`}
                    type="text"
                    placeholder="Enter Status"
                    {...register("status")}
                  />
                  <small className="text-danger">
                    {errors.status?.message}
                  </small>
                </div>
                <div className="form-group mt-3 ">
                  <label>Action Date</label>
                  <input
                    className={`form-control ${errors.action_date && "border border-danger"
                      }`}
                    name="action_date"
                    type="date"
                    {...register("action_date")}
                  />
                  <small className="text-danger">
                    {errors.action_date?.message}
                  </small>
                </div>
                <div className="form-group mt-3 ">
                  <label>Action By </label>
                  <input
                    name="action_by_text"
                    className={`form-control ${errors.action_by_text && "border border-danger"
                      }`}
                    type="text"
                    placeholder="Enter Action Text"
                    {...register("action_by_text")}
                  />
                  <small className="text-danger">
                    {errors.action_by_text?.message}
                  </small>
                </div>
                <div className="form-group mt-3 ">
                  <label>Previous Action Date</label>
                  <input
                    className={`form-control ${errors.previous_action_date && "border border-danger"
                      }`}
                    name="previous_action_date"
                    type="date"
                    {...register("previous_action_date")}
                  />
                  <small className="text-danger">
                    {errors.previous_action_date?.message}
                  </small>
                </div>
              </div>
            </div>
          </div> */}
        </div>

        <div className="row">
          <div className="col-md-12">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={Boolean(loading)}
            >
              {loading ? (
                <>
                  <span className="fa fa-spinner fa-spin"></span>
                  <span>{"Loading..."}</span>
                </>
              ) : (
                <span>{"Submit"}</span>
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
