import React, { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import "./MyCalendar.css";
import {
  getAllRequirmentData,
  getCompanyById,
  getEventDataByCompanyCode,
} from "store/services/company.service";
import { AlertCircle, CheckCircle, Info, PlusCircle } from "react-feather";
import { toast } from "react-toastify";
import { darkStyle } from "components/defaultStyles";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { AddStatuaryEvent } from "../Staturtory/addStatuaryEvent";
import { ViewEvent } from "../Staturtory/viewEvent";
import { FaCalendarAlt } from "react-icons/fa";
import moment from "moment";
import { ViewRequirment } from "../Staturtory/viewRequirment"
// import loader  from "../../../assets/images/loader.svg";
import loader from "../../../assets/images/dcc_loader.svg";
import { FaDotCircle } from "react-icons/fa";
import { CiWarning } from "react-icons/ci";

const MyCalendar = ({ dashboard = undefined }) => {
  const setTimeOfTheDateToMidNight = (date) => {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    return date;
  };

  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthsArray = [
    "-01",
    "-02",
    "-03",
    "-04",
    "-05",
    "-06",
    "-07",
    "-08",
    "-09",
    "-10",
    "-11",
    "-12",
  ];

  const [baseCompany, setBaseCompany] = useState(
    sessionStorage.getItem("company_code")
  );
  const [currentDate, setCurrentDate] = useState(
    setTimeOfTheDateToMidNight(new Date())
  );
  const [companies_data_loading, setCompanies_data_loading] = useState(false);
  const [selectedCompanyData, setSelectedCompanyData] = useState({});
  const [eventData, setEventData] = useState([]);
  const [allRequirments, setAllRequirements] = useState([]);
  const [quarterMonths, setQuarterMonths] = useState([]);
  const [satuatoryEvents, setSatuatoryEvents] = useState([]);
  const [expandedDates, setExpandedDates] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState({
    open: false,
    data: {},
    currentDate: null,
  });
  const [addEvent, setAddEvent] = useState(false);
  const [viewEvent, setViewEvent] = useState(false);
  const [viewRequirement, setViewRequirement] = useState(false);
  const [calendarState, setCalendarState] = useState({
    month: true,
    day: false,
  });
  const [loading, setLoading] = useState(false);
  const email = sessionStorage.getItem("email");

  useEffect(() => {
    const handleStorageChange = () => {
      // window.location.reload();
      setBaseCompany(sessionStorage.getItem("company_code"));
      // dashboardFunctionality();
      // getShareHolerGraphData();
      return;
    };

    window.addEventListener("storage", handleStorageChange);

    getAllRequirments();
    getSatuatoryEventsByCompany();
    getCompanyInformation();

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  function getQuarter(date, fiscalYear) {

    const dateObj = new Date(date);


    // const fiscalYearStartMonth = parseInt(fiscalYear, 10) - 1; // Convert fiscal year start month to zero-based index
    const fiscalYearStartMonth = parseInt(moment().month(fiscalYear).format('M')) - 1; // Convert fiscal year start month to zero-based index
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth(); // Get month of the given date (zero-based index)

    // Calculate the adjusted month based on fiscal year start
    const adjustedMonth = (month + 12 - fiscalYearStartMonth) % 12;

    // Determine the quarter number based on the adjusted month
    const quarter = Math.floor(adjustedMonth / 3) + 1;

    return quarter;
  }

  useEffect(() => {
    resetData();
    if (!baseCompany) {
      toast.error("Select company in header!");
    }
  }, [baseCompany]);

  useEffect(() => {
    if (allRequirments && selectedCompanyData && satuatoryEvents && allRequirments.length > 0 && satuatoryEvents.length > 0 && selectedCompanyData?.fiscal_year)
      mapEvents(allRequirments, true);
  }, [allRequirments, satuatoryEvents, selectedCompanyData]);

  const formatDate = (date) => {
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const formatDateString = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 because getMonth() returns 0-11
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const isDateInRange = (date, startDate, endDate) => {
    const dateUTC = Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    const startDateUTC = Date.UTC(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate()
    );
    const endDateUTC = Date.UTC(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate()
    );

    return dateUTC >= startDateUTC && dateUTC <= endDateUTC;
  };

  const getFirstWorkingDay = (date) => {
    let currentDate = moment(date).startOf("month"); // Start at the first day of the month
    while (currentDate.day() === 0 || currentDate.day() === 6) {
      // 0 = Sunday, 6 = Saturday
      currentDate.add(1, "days"); // Move to the next day
    }
    return new Date(currentDate);
  };

  const mapEvents = (data, isRequirement = false) => {

    if (!isRequirement) {
      const gettingDateForEvent = allRequirments
        .map((item) => {
          const quarterData = data.find(
            (singleData) => singleData.id === item?.quarter
          );
          if (quarterData && quarterData?.start) {
            let quarterDate = new Date(quarterData.start);

            return { ...item, dateForEvent: quarterDate };
          }
          return { ...item, dateForEvent: null };
        })
        .filter((item) => item.dateForEvent !== null)
        .map((item, index) => {
          let startDay = new Date(item.dateForEvent);
          startDay.setDate(startDay.getDate() + parseInt(item?.start_days || 0));
          startDay.setHours(0, 0, 0, 0);
          let endDay = new Date(item.dateForEvent);
          endDay.setDate(
            endDay.getDate() +
            parseInt(
              (item?.deadline_days == item?.start_days
                ? item?.deadline_days
                : item?.deadline_days - 1) || 0
            )
          );
          endDay.setHours(0, 0, 0, 0);
          const firstRequirement = allRequirments.find(
            (requirement) => requirement.quarter == item.quarter
          );

          return {
            id: `QE${index + 1}`,
            title: (
              <div className="custom-event-title">
                <div className="d-flex justify-content-start align-items-center">
                  <PlusCircle size={20} color="green" /> &nbsp;{" "}

                  {/* {truncateText(item?.title || firstRequirement?.title) ||
                    `Add Event`}{" "} */}
                  <span style={{ width: "100%" }} className="text-truncate text-left">{item?.title || firstRequirement?.title ||
                    `Add Event`}{" "}</span>
                  &nbsp;{" "}
                </div>
              </div>
            ),
            titlee: item?.title || firstRequirement?.title || `Add Event`,
            allDay: true,
            start: startDay,
            end: endDay,
            quarterEvent: true,
            quarter: item.quarter,
            multipleDays: startDay == endDay,
            item: item,
          };
        });

      setEventData((prevEventData) => [...prevEventData, ...gettingDateForEvent]);
    }

    else {

      let fiscalMonth = selectedCompanyData?.fiscal_year
      let finalRequirementsforEvents = allRequirments.filter(req => {
        let notFound = true;

        for (let i = 0; i < satuatoryEvents.length; i++) {
          if (satuatoryEvents[i]?.req_code && satuatoryEvents[i]?.req_code.split("-")[0].trim() === req.code && (moment(satuatoryEvents[i]?.start_date).format("YYYY") === moment().year().toString())) {
            notFound = false;
            break;
          }
        }

        return notFound;
      })

      finalRequirementsforEvents = finalRequirementsforEvents.filter(item => item?.quarter);

      const gettingDateForEvent = finalRequirementsforEvents
        .map((item) => {
          let quarterMonthsToAdd = item?.quarter.toLowerCase() === "q1" ? 0 : item?.quarter.toLowerCase() === "q2" ? 3 : item?.quarter.toLowerCase() === "q3" ? 6 : item?.quarter.toLowerCase() === "q4" ? 9 : 0;
          let date = moment().month(fiscalMonth)?.startOf('month').add(quarterMonthsToAdd, 'months')
          let startDate = date
          if (item && item?.start_days) {
            startDate = startDate.add(parseInt(item?.start_days), 'days');
          }
          startDate = startDate.format("YYYY-MM-DD");
          let endDate = date
          if (item && item?.deadline_days) {
            endDate = endDate.add(parseInt(item?.deadline_days), 'days');
          }
          endDate = endDate.format("YYYY-MM-DD");
          let quarterStartDate = new Date(startDate);
          let quarterEndDate = new Date(endDate);

          return { ...item, dateForEvent: { start: quarterStartDate, end: quarterEndDate } };

        })
        .filter((item) => item.dateForEvent !== null)
        .map((item, index) => {
          let startDay = new Date(item.dateForEvent.start);
          startDay.setHours(0, 0, 0, 0);
          let endDay = new Date(item.dateForEvent.end);

          endDay.setHours(0, 0, 0, 0);

          return {
            id: `QA${index + 1}`,
            title: (
              <div className="custom-event-title">
                <div className="d-flex justify-content-start align-items-center">
                  <CiWarning size={20} color="yellow" /> &nbsp;{" "}

                  {/* {truncateText(item?.title)} */}
                  <span style={{ width: "100%" }} className="text-truncate text-left">{item?.title}</span>
                  {/* Add Event */}
                  &nbsp;{" "}
                </div>
              </div>
            ),
            titlee: `Add Event`,
            allDay: true,
            start: startDay,
            end: endDay,
            quarterEvent: true,
            quarter: item.quarter,
            multipleDays: startDay == endDay,
            item: item,
          };
        });

      setEventData((prevEventData) => [...prevEventData, ...gettingDateForEvent]);


    }


  };

  const getCompanyInformation = async () => {
    setCompanies_data_loading(true);
    try {
      const response = await getCompanyById(email, baseCompany);
      if (response.status === 200 && response.data.status === 200) {
        let findingCompany = response.data.data;
        setSelectedCompanyData(response.data.data);
        // setCompanies(response.data.data);

        let quarterMonthsArr = [];
        const numberOfYears = 5;

        if (findingCompany.fiscal_year) {
          // const fiscalYearStart = parseInt(
          //   findingCompany.fiscal_year.split("-")[0]
          // );
          const fiscalYearStart = moment().year();
          // const fiscalYearMonth = findingCompany.fiscal_year.split("-")[1];
          const fiscalYearMonth = parseInt(moment().month(findingCompany.fiscal_year).format('M'));

          for (let i = -numberOfYears; i <= numberOfYears; i++) {
            const currentYear = fiscalYearStart + i;
            const nextYear = currentYear + 1;

            // if (fiscalYearMonth === "07") {
            //   quarterMonthsArr = quarterMonthsArr.concat(
            //     ["07", "10", "01", "04"].map((item, index) => {
            //       if (index < 2) {
            //         return currentYear + "-" + item + "-01";
            //       } else {
            //         return nextYear + "-" + item + "-01";
            //       }
            //     })
            //   );
            // } else {
            //   quarterMonthsArr = quarterMonthsArr.concat(
            //     ["01", "04", "07", "10"].map(
            //       (item, index) => currentYear + "-" + item + "-01"
            //     )
            //   );
            // }

            const quarterMonths = [
              fiscalYearMonth,
              (fiscalYearMonth + 3) % 12 || 12,
              (fiscalYearMonth + 6) % 12 || 12,
              (fiscalYearMonth + 9) % 12 || 12
            ];

            quarterMonthsArr = quarterMonthsArr.concat(
              quarterMonths.map((month, index) => {
                const formattedMonth = month.toString().padStart(2, '0');
                if (index < 4) {
                  return (index < 2 ? currentYear : nextYear) + "-" + formattedMonth + "-01";
                }
              })
            );
          }

          setQuarterMonths(quarterMonthsArr);
          const currentYear = new Date().getFullYear();
          addQuarterEvents(
            currentYear,
            quarterMonthsArr,
            findingCompany.fiscal_year
          );
        }

        setCompanies_data_loading(false);
        // setLoading(false);
      }
    } catch (error) {
      setCompanies_data_loading(false);
    }
  };

  const resetData = () => {
    setEventData([]);
    setSelectedCompanyData({});
    setQuarterMonths([]);
    setSatuatoryEvents([]);
  };

  const getAllRequirments = async () => {
    try {
      setLoading(true);
      const response = await getAllRequirmentData(email);
      if (response.status == 200) {
        setAllRequirements(response.data.data.filter((item) => item?.quarter));
      } else {
        setAllRequirements([]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);

      if (error.response != undefined) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  const renderHeader = () => {
    return (
      <>
        <div
          className={
            screen <= 500
              ? " onSmallHeading1 mt-2"
              : " d-flex justify-content-between mt-2 ml-3"
          }
          style={{ gap: "20px" }}
        >
          <div
            className={
              screen <= 500
                ? "text-center d-flex mt-2 "
                : "text-center d-flex mt-2 ml-3"
            }
            style={{ gap: "20px" }}
          >
            <FaCalendarAlt className="text-muted" size={30} />
            <div className="d-flex flex-column justify-content-start text-start">
              <h5 style={{ color: "#04b4fa" }}>Compliance Calendar</h5>
              <p>Statutory Requirements and Compliance Calendar</p>
            </div>

          </div>
          {selectedCompanyData?.fiscal_year && (
            <div>
              Fiscal Year:{" "}
              {selectedCompanyData?.fiscal_year} - {moment().month(selectedCompanyData?.fiscal_year).subtract(1, "months").format('MMMM')}
            </div>
          )}
        </div>
        <div className={"header row "}>
          <div
            style={{ gap: screen <= 500 ? "10px" : "" }}
            className={
              screen <= 500
                ? "col-12 col-start d-flex flex-column mb-2"
                : "col col-start d-flex"
            }
          >
            <Button
              size="sm"
              className="ml-2"
              variant="secondary"
              onClick={() => prevMonth()}
            >
              Previous
            </Button>
            <Button
              size="sm"
              className="ml-2"
              variant="secondary"
              onClick={() => today()}
            >
              Today
            </Button>
            <Button
              size="sm"
              className="ml-2"
              variant="secondary"
              onClick={() => nextMonth()}
            >
              Next
            </Button>
            <div className="ml-2">
              <input
                name="calendar_state"
                className="form-control"
                value={
                  calendarState.month
                    ? new Date(currentDate).getFullYear().toString() +
                    monthsArray[new Date(currentDate).getMonth()]
                    : formatDateString(new Date(currentDate))
                }
                type={calendarState.month ? "month" : "date"}
                onChange={(e) =>
                  setCurrentDate(
                    setTimeOfTheDateToMidNight(new Date(e.target.value))
                  )
                }
              />
            </div>
          </div>
          <div className="col col-center">
            <h6>
              {currentDate.toLocaleDateString("en-US", {
                month: "long",
                year: "numeric",
              })}
            </h6>
          </div>
          <div
            className={
              screen <= 500
                ? "col col-end d-flex justify-content-center"
                : "col col-end d-flex justify-content-end"
            }
          >
            <Button
              size="sm"
              className="ml-2"
              variant="secondary"
              onClick={() => handleMonth()}
            >
              Month
            </Button>
            <Button
              size="sm"
              className="ml-2"
              variant="secondary"
              onClick={() => handleDay()}
            >
              Day
            </Button>
          </div>
        </div>
      </>
    );
  };

  const renderDays = () => {
    const daysOfWeek = Array.from({ length: 7 }).map((_, i) =>
      new Date(2021, 7, i + 1).toLocaleDateString("en-US", { weekday: "long" })
    );

    return (
      <div
        style={{ width: urlpath && screen <= 500 ? "243vw" : "" }}
        className="days row"
      >
        {daysOfWeek.map((day, i) =>
          screen <= 1024 && !urlpath ? (
            <p
              style={{
                fontSize: screen <= 1024 && screen >= 768 ? "14px" : "10px",
                marginBottom: "0px",
              }}
              className="col col-center dayDesign"
              key={i}
            >
              {day?.slice(0, 3)}
            </p>
          ) : (
            <h6 className="col col-center dayDesign" key={i}>
              {day}
            </h6>
          )
        )}
      </div>
    );
  };

  const addQuarterEvents = (year, quarterMonths, fiscal_year) => {

    const newEvents = quarterMonths.map((item, index) => {
      const firstWorkingDay = getFirstWorkingDay(new Date(item));
      firstWorkingDay.setHours(0, 0, 0, 0);
      return {
        id: `Quarter${index + 1}`,
        title: (
          <div className="custom-event-title">
            <div className="d-flex justify-content-start align-items-center">
              <AlertCircle size={20} color="#FF0000" />
              {/* &nbsp;{`Quarter ${getQuarter(firstWorkingDay, fiscal_year)}`}{" "} */}
              &nbsp;<span style={{ width: "100%", textAlign: "start" }} className="text-truncate">{`Quarter ${getQuarter(firstWorkingDay, fiscal_year)}`}</span>{" "}
              &nbsp;{" "}
            </div>
          </div>
        ),
        ide: `Quarter${getQuarter(firstWorkingDay, fiscal_year)}`,
        titlee: `First Working Day of Quarter`,
        allDay: true,
        start: firstWorkingDay,
        end: firstWorkingDay,
        quarterEvent: true,
      };
    });

    setEventData((prevEventData) => {
      mapEvents([...prevEventData, ...newEvents]);
      return [...prevEventData, ...newEvents];
    });
  };

  const getSatuatoryEventsByCompany = async () => {
    try {
      setLoading(true);
      //   const response = await getAllEventData(email);
      const response = await getEventDataByCompanyCode(
        email,
        baseCompany
      );
      if (response.data.status == 200) {
        // const selectedCompanyEvents = response.data.data.filter(
        //   (item) => item?.start_date
        // );
        // setSatuatoryEvents(selectedCompanyEvents);
        setSatuatoryEvents(response.data.data);
        // const companyEvents = selectedCompanyEvents.map((item, index) => {
        const companyEvents = response.data.data.map((item, index) => {
          let startDate = new Date(item.start_date);
          startDate.setHours(0, 0, 0, 0);
          const endDate = new Date(item.deadline_date);
          endDate.setHours(0, 0, 0, 0);

          const now = new Date();
          const diff = endDate.getTime() < now.getTime();

          return {
            id: `QSE${index + 1}`,
            title: (
              <div className="custom-event-title">
                {diff ? (
                  <div className="d-flex justify-content-start align-items-center">
                    <CheckCircle size={20} color="green" /> &nbsp;{" "}
                    {/* {truncateText(item.title)} &nbsp; */}
                    <span style={{ width: "100%" }} className="text-truncate text-left">{item.title}</span> &nbsp;
                  </div>
                ) : (
                  <div className="d-flex justify-content-start align-items-center">
                    <Info size={20} color="black" /> &nbsp;{" "}
                    <span style={{ width: "100%", textAlign: "start" }} className="text-truncate">{item.title}</span> &nbsp;
                  </div>
                )}
              </div>
            ),
            titlee: item.title,
            allDay: true,
            start: startDate,
            end: endDate,
            quarterEvent: true,
            eventPassed: diff,
            item: item,
          };
        });

        setEventData((prevEventData) => [...prevEventData, ...companyEvents]);
      } else {
        setSatuatoryEvents([]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);

      if (error.response != undefined) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  const renderCells1 = () => {
    const monthStart = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const monthEnd = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    const startDate =
      monthStart.getDay() === 0
        ? monthStart
        : new Date(
          monthStart.setDate(monthStart.getDate() - monthStart.getDay())
        );
    const endDate =
      monthEnd.getDay() === 6
        ? monthEnd
        : new Date(
          monthEnd.setDate(monthEnd.getDate() + (6 - monthEnd.getDay()))
        );

    const rows = [];
    let days = [];
    let day = startDate;

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        const formattedDate = day.toLocaleDateString("en-US", {
          day: "numeric",
        });
        const cloneDay = new Date(day);

        // Hide PlusCircle on weekends
        const isWeekend = cloneDay.getDay() === 0 || cloneDay.getDay() === 6;

        // Skip weekends for events
        const eventsForDay = eventData.filter((event) => {
          // if (isWeekend) return false;
          return (
            isSameDay(event.start, cloneDay) || isSameDay(event.end, cloneDay)
          );
        });

        const isExpanded = expandedDates.includes(cloneDay.getTime());
        days.push(
          <div
            className={`col cell ${day.getMonth() !== currentDate.getMonth() ? "disabled" : ""
              }`}
            key={cloneDay.getTime()}
            // onClick={() => (!isWeekend ? onDateClick(cloneDay) : null)}
            onClick={() => (
              dashboard ? null : onDateClick(cloneDay)
            )}
          >
            <div className="d-flex w-100 justify-content-between">
              <span className="number">{formattedDate}</span>
              {/* <div>{!isWeekend && <PlusCircle />}</div> */}
              <div><PlusCircle /></div>
            </div>
            <div className={`events-container ${isExpanded ? "expanded" : ""}`}>
              <div>
                {eventsForDay.map((event, index) => {
                  const eventStartDate = new Date(event.start);
                  const eventEndDate = new Date(event.end);
                  if (event.id.startsWith("QE")) {

                    if (isSameDay(cloneDay, eventStartDate)) {
                      return (
                        <div
                          onClick={(e) => {
                            handleEventsClick(e, event, cloneDay)

                          }}
                          key={index}
                          className={`event ${event?.multipleDays
                            ? "isEventForMultiple"
                            : "isEvent"
                            }`}
                        >
                          {event.title}
                        </div>
                      );
                    } else {
                      return (
                        <div
                          onClick={(e) => {

                            handleEventsClick(e, event, cloneDay)

                          }}
                          key={index}
                          className={`event ${event?.multipleDays
                            ? "isEventForMultiple"
                            : "isEvent"
                            }`}
                        >
                          <div className="custom-event-title">
                            <div className="d-flex justify-content-start align-items-center">
                              <AlertCircle size={20} color="#FF0000" /> &nbsp;{" "}
                              {/* {truncateText(event?.item?.title) || `Add Event`}{" "} */}
                              <span style={{ width: "100%" }} className="text-truncate text-left">{event?.item?.title || `Add Event`}{" "}</span>
                              &nbsp;
                            </div>
                          </div>
                        </div>
                      );
                    }
                  }

                  return (
                    <div
                      onClick={(e) => {

                        handleEventsClick(e, event, cloneDay)

                      }}
                      key={index}
                      className={`event ${event?.multipleDays ? "isEventForMultiple" : "isEvent"
                        }`}
                    >
                      {event.title}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );

        day = new Date(day.setDate(day.getDate() + 1));
      }

      rows.push(
        <div className="row" key={day}>
          {days}
        </div>
      );
      days = [];
    }

    return <div className="body">{rows}</div>;
  };

  const countWeekendDays = (startDate, endDate) => {
    let count = 0;
    let current = new Date(startDate);
    while (current <= endDate) {
      const day = current.getDay();
      if (day === 0 || day === 6) {
        // Sunday or Saturday
        count++;
      }
      current = new Date(current.setDate(current.getDate() + 1));
    }
    return count;
  };

  const renderCells = () => {
    const monthStart = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const monthEnd = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    const startDate =
      monthStart.getDay() === 0
        ? monthStart
        : new Date(
          monthStart.setDate(monthStart.getDate() - monthStart.getDay())
        );
    const endDate =
      monthEnd.getDay() === 6
        ? monthEnd
        : new Date(
          monthEnd.setDate(monthEnd.getDate() + (6 - monthEnd.getDay()))
        );

    const today = new Date();
    const rows = [];
    let days = [];
    let day = startDate;

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        const formattedDate = day.toLocaleDateString("en-US", {
          day: "numeric",
        });
        const cloneDay = new Date(day);
        const isToday = cloneDay.toDateString() === today.toDateString();

        // Hide PlusCircle on weekends
        const isWeekend = cloneDay.getDay() === 0 || cloneDay.getDay() === 6;

        // Skip weekends for events and adjust their end dates
        const eventsForDay = eventData.filter((event) => {
          let eventStartDate = new Date(event.start);
          let eventEndDate = new Date(event.end);

          // Calculate the number of weekend days in the event duration
          const weekendDaysCount = countWeekendDays(
            eventStartDate,
            eventEndDate
          );
          // if (event.titlee == "aisa nam jo mil jay")

          // Extend the event end date by the number of weekend days
          // if (weekendDaysCount > 0) {
          //   eventEndDate.setDate(eventEndDate.getDate() + weekendDaysCount);
          // }

          // Move event from weekend to the next weekday
          // while (
          //   eventStartDate.getDay() === 0 ||
          //   eventStartDate.getDay() === 6
          // ) {
          //   eventStartDate.setDate(eventStartDate.getDate() + 1);
          // }
          // while (eventEndDate.getDay() === 0 || eventEndDate.getDay() === 6) {
          //   eventEndDate.setDate(eventEndDate.getDate() + 1);
          // }

          return (
            isSameDay(eventStartDate, cloneDay) ||
            isSameDay(eventEndDate, cloneDay)
          );
        });

        const isExpanded = expandedDates.includes(cloneDay.getTime());
        days.push(
          <div
            style={{ minHeight: screen <= 500 ? "0px" : "", }}
            className={`col ${dashboard ? "cell-dashboard" : "cell"} ${day.getMonth() !== currentDate.getMonth() ? "disabled" : ""
              } ${isToday ? "today" : ""}`}
            key={cloneDay.getTime()}
            // onClick={() => (!isWeekend ? onDateClick(cloneDay) : null)}
            onClick={() => (dashboard ? null : onDateClick(cloneDay))}
          >
            <div className="d-flex w-100 justify-content-between">
              <span className="number">{formattedDate}</span>
              {/* {!dashboard && <div>{!isWeekend && <PlusCircle />}</div>} */}
              {!dashboard && <div><PlusCircle /></div>}
            </div>
            <div
              style={{
                overflowY: "auto",
                height: "115px",
                width: "-webkit-fill-available"
              }}
            >
              <div
                className={`events-container ${isExpanded ? "expanded" : ""}`}>
                <div
                  style={{
                    width: "inherit"
                  }}
                >
                  {eventsForDay.map((event, index) => {
                    const eventStartDate = new Date(event.start);
                    const eventEndDate = new Date(event.end);
                    if (event.id.startsWith("QE")) {

                      if (isSameDay(cloneDay, eventStartDate)) {
                        if (!dashboard) {
                          return (
                            <div
                              onClick={(e) => {

                                handleEventsClick(e, event, cloneDay)

                              }
                              }
                              key={index}
                              className={`event ${event?.multipleDays
                                ? "isEventForMultiple"
                                : "isEvent"
                                }`}
                            >
                              {event.title}
                            </div>
                          );
                        } else {
                          return (
                            <div
                              onClick={(e) => {

                                handleEventsClick(e, event, cloneDay)

                              }
                              }
                              key={index}
                              className={`event ${event?.multipleDays
                                ? "isEventForMultiple"
                                : "isEvent"
                                }`}
                            >
                              <FaDotCircle />
                            </div>
                          );
                        }
                      } else {
                        return (
                          <div
                            onClick={(e) => {
                              handleEventsClick(e, event, cloneDay)

                            }}
                            key={index}
                            className={`event ${event?.multipleDays
                              ? "isEventForMultiple"
                              : "isEvent"
                              }`}
                          >
                            <div className="custom-event-title">
                              <div className="d-flex justify-content-start align-items-center">
                                <AlertCircle size={20} color="#FF0000" /> &nbsp;{" "}
                                {/* {truncateText(event?.item?.title) || `Add Event`}{" "} */}
                                <span style={{ width: "100%" }} className="text-truncate text-left">{event?.item?.title || `Add Event`}{" "}</span>
                                &nbsp;
                              </div>
                            </div>
                          </div>
                        );
                      }
                    }

                    return (
                      <div
                        onClick={(e) => {

                          handleEventsClick(e, event, cloneDay)

                        }}
                        key={index}
                        className={`event ${event?.multipleDays ? "isEventForMultiple" : "isEvent"
                          }`}
                      >
                        {event.title}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        );

        day = new Date(day.setDate(day.getDate() + 1));
      }

      rows.push(
        <div className="row" key={day}>
          {days}
        </div>
      );
      days = [];
    }

    return (
      <div
        style={{
          width: urlpath && screen <= 500 ? "235vw" : "",
          marginLeft: urlpath && screen <= 500 ? "1rem" : "",
        }}
        className="body"
      >
        {rows}
      </div>
    );
  };

  const isSameDay = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  const onDateClick = (day) => {
    sessionStorage.setItem(
      "SelectedDateForEvent",
      JSON.stringify({
        // date: new Date(new Date(day).getTime()),
        date: new Date(new Date(day).getTime() + 24 * 60 * 60 * 1000),
        company: baseCompany,
      })
    );
    setAddEvent(true);
  };

  const today = () => {
    setCurrentDate(setTimeOfTheDateToMidNight(new Date()));
  };

  const nextMonth = () => {
    if (calendarState.month) {
      setCurrentDate(
        new Date(currentDate.setMonth(currentDate.getMonth() + 1))
      );
    } else {
      setCurrentDate((prevDate) =>
        setTimeOfTheDateToMidNight(
          new Date(prevDate.setDate(prevDate.getDate() + 1))
        )
      );
    }
  };

  const prevMonth = () => {
    if (calendarState.month) {
      setCurrentDate(
        new Date(currentDate.setMonth(currentDate.getMonth() - 1))
      );
    } else {
      setCurrentDate((prevDate) =>
        setTimeOfTheDateToMidNight(
          new Date(prevDate.setDate(prevDate.getDate() - 1))
        )
      );
    }
  };

  const handleEventsClick = (e, item, currentDate) => {
    e.stopPropagation();

    sessionStorage.setItem("requirementForDates", JSON.stringify(item))

    if (item?.id.startsWith("QA")) {
      sessionStorage.setItem("selectedrequirment", JSON.stringify({
        ...item.item,
        selectedData: {
          date: new Date(currentDate).toISOString(),
          company: baseCompany,
        },
      }))
      setViewRequirement(true);
      return null
    }

    if (item?.id.startsWith("Quarter")) {
      setSelectedEvent({ open: true, data: item, currentDate: currentDate });
      return null;
    }

    if (item?.id.startsWith("QSE")) {
      sessionStorage.setItem("selectedEvent", JSON.stringify(item.item));
      setViewEvent(true);
      return null;
    }

    if (item?.id.startsWith("QE")) {
      sessionStorage.setItem(
        "selectedrequirment",
        JSON.stringify({
          ...item.item,
          selectedData: {
            date: new Date(currentDate).toISOString(),
            company: baseCompany,
          },
        })
      );
      setViewRequirement(true);
      return null;
    }
  };

  const truncateText = (text, charLimit = dashboard ? 5 : 18) => {
    if (calendarState.day) {
      return text;
    }

    if (text.length > charLimit) {
      return text.substring(0, charLimit) + "...";
    }
    return text;
  };

  const handleMonth = () => {
    setCalendarState({
      day: false,
      month: true,
    });
    setCurrentDate(setTimeOfTheDateToMidNight(new Date()));
  };

  const handleDay = () => {
    setCalendarState({
      day: true,
      month: false,
    });
    setCurrentDate(setTimeOfTheDateToMidNight(new Date()));
  };

  const currentDateEvents = useMemo(
    () =>
      eventData.filter((item) => {
        if (!calendarState.day) {
          return;
        }
        return isDateInRange(currentDate, item.start, item.end) && item;
      }),
    [eventData, currentDate]
  );

  const [screen, setScreen] = useState();
  const [urlpath, setUrlpath] = useState();
  useEffect(() => {
    const handleResize = () => {
      setScreen(window?.outerWidth);
      setUrlpath(window.location.pathname == "/event-calendar");
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={!dashboard ? "pt-3 col-12" : "col-12"}>
      <div className={screen <= 500 ? "col-12 px-0" : "col-12"}>
        {!baseCompany && (
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <div
                  className="d-flex justify-content-center align-items-center text-center"
                  style={{ height: "30vh" }}
                >
                  <h5>Select company in header!</h5>
                </div>
              </div>
            </div>
          </div>
        )}
        {baseCompany && (
          <div className={"card p-4 calendar"}>
            {!dashboard && renderHeader()}
            {!companies_data_loading && !loading ? (
              <>
                {calendarState.month && (
                  <>
                    {urlpath && screen <= 500 ? (
                      <div
                        style={{ minWidth: "242px", overflow: "auto" }}
                        className="test"
                      >
                        {renderDays()}
                        {renderCells()}
                      </div>
                    ) : (
                      <>
                        {renderDays()}
                        {renderCells()}
                      </>
                    )}
                  </>
                )}
                {calendarState.day && (
                  <>
                    <table className="table  ">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Day</th>
                          <th className="d-flex justify-content-center">
                            Event
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentDateEvents.length > 0 ? (
                          currentDateEvents.map((item) => (
                            <tr key={item.id}>
                              <td>{formatDate(currentDate)}</td>
                              <td>{weekdays[currentDate.getDay()]}</td>
                              <td>{item.title}</td>
                            </tr>
                          ))
                        ) : (
                          <h1>No Events Found For Today</h1>
                        )}
                      </tbody>
                    </table>
                  </>
                )}
              </>
            ) : (
              <div className="d-flex justify-content-center align-items-center h-100">
                <img alt="dcc_loader" src={loader} />
              </div>
            )}
          </div>
        )}

        {!dashboard && satuatoryEvents && (
          <MapSatuatoryEvents satuatoryEvents={satuatoryEvents} />
        )}

        {selectedEvent.open && (
          <Modal
            style={{
              minWidth: screen <= 1199 ? "95%" : ""
            }}
            toggle={() => {
              setSelectedEvent({ open: false, data: {}, currentDate: null });
            }}
            isOpen={selectedEvent.open} size="xl">
            <ModalHeader
              toggle={() => {
                setSelectedEvent({ open: false, data: {}, currentDate: null });
              }}
            >
              Statutory Requirements
            </ModalHeader>
            <ModalBody>
              <table className="table mb-2">
                <thead>
                  <tr>
                    <th>Company Type</th>
                    <th>Title</th>
                    <th>Level</th>
                    <th>Start Date</th>
                    <th>Deadline Date</th>
                    {!dashboard && <th></th>}
                  </tr>
                </thead>
                <tbody>
                  {allRequirments
                    .filter((requirement) => {
                      let currentSelectedEvent =
                        selectedEvent.data.ide.split("Quarter")[1];
                      currentSelectedEvent = `Q${currentSelectedEvent}`;
                      return requirement.quarter == currentSelectedEvent;
                    })
                    .map((item) => {
                      let startDay = new Date(selectedEvent.currentDate);
                      startDay.setDate(
                        startDay.getDate() + parseInt(item?.start_days || 0)
                      );
                      startDay.setHours(0, 0, 0, 0);
                      let endDay = new Date(selectedEvent.currentDate);
                      endDay.setDate(
                        endDay.getDate() +
                        parseInt(
                          (item?.deadline_days == item?.start_days
                            ? item?.deadline_days
                            : item?.deadline_days - 1) || 0
                        )
                      );
                      endDay.setHours(0, 0, 0, 0);
                      return (
                        <tr key={item._id}>
                          <td>{item.company_type}</td>
                          <td>{item.title}</td>
                          <td>{item.level}</td>
                          <td>{startDay.toISOString().split("T")[0]}</td>
                          <td>{endDay.toISOString().split("T")[0]}</td>
                          {!dashboard && <td>
                            <button
                              onClick={() => {
                                if (!dashboard) {
                                  if (item?.frequency === "Annual") {
                                    toast.info("Annual Event Already Exist!");
                                    return;
                                  }
                                  sessionStorage.setItem(
                                    "tempRequirementDetails",
                                    JSON.stringify({
                                      ...item,
                                      selectedCompanyData: selectedCompanyData,
                                    })
                                  );
                                  setAddEvent(true);
                                }

                              }}
                              type="button"
                              className="btn btn-primary"
                            >
                              Add Event
                            </button>
                          </td>}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </ModalBody>
          </Modal>
        )}

        {addEvent && (
          <Modal
            style={{
              minWidth: screen <= 1199 ? "95%" : ""
            }}
            toggle={() => {
              setAddEvent(false);
              sessionStorage.removeItem("tempRequirementDetails");
            }}
            isOpen={addEvent} show={addEvent.toString()} size="xl">
            <ModalHeader
              toggle={() => {
                setAddEvent(false);
                sessionStorage.removeItem("tempRequirementDetails");
              }}
            >
              Add Event
            </ModalHeader>
            <ModalBody>
              <AddStatuaryEvent setAddEvent={setAddEvent} />
            </ModalBody>
          </Modal>
        )}

        {viewEvent && (
          <Modal
            style={{
              minWidth: screen <= 1199 ? "95%" : ""
            }}
            toggle={() => {
              setViewEvent(false);
              sessionStorage.removeItem("tempRequirementDetails");
            }}
            isOpen={viewEvent} show={viewEvent.toString()} size="xl">
            <ModalHeader
              toggle={() => {
                setViewEvent(false);
                sessionStorage.removeItem("tempRequirementDetails");
              }}
            >
              View Event
            </ModalHeader>
            <ModalBody>
              <ViewEvent showID={true} />
            </ModalBody>
          </Modal>
        )}

        {viewRequirement && (
          <Modal
            style={{
              minWidth: screen <= 1199 ? "95%" : ""
            }}
            toggle={() => {
              setViewRequirement(false);
              sessionStorage.removeItem("selectedrequirment");
            }}
            isOpen={viewRequirement}
            show={viewRequirement.toString()}
            size="xl"
          >
            <ModalHeader
              toggle={() => {
                setViewRequirement(false);
                sessionStorage.removeItem("selectedrequirment");
              }}
            >
              Statutory Requirement View
            </ModalHeader>
            {!dashboard && <div className="col-12 mt-2 d-flex justify-content-end">
              <Button
                onClick={() => {
                  const data = JSON.parse(
                    sessionStorage.getItem("selectedrequirment")
                  );
                  onDateClick(data.selectedData.date);
                }}
              >
                Add Event
              </Button>
            </div>}
            <ModalBody>
              <ViewRequirment />
            </ModalBody>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default MyCalendar;

const MapSatuatoryEvents = ({ satuatoryEvents }) => (
  <div className="mt-4">
    {satuatoryEvents.length > 0 && (
      <div className="table-responsive">
        <table className="table ">
          <thead>
            <tr>
              <th>Event Title</th>
              <th>Event Requirement</th>
              {/* <th>Start Date</th> */}
              <th>Event Duration</th>
              {/* <th>Duration</th> */}
              {/* <th>End Date</th> */}
              <th>Event Passed</th>
            </tr>
          </thead>

          <tbody>
            {satuatoryEvents.map((item, i) => {
              const now = new Date();
              const diff = new Date(item.start_date).getTime() < now.getTime();
              return (
                <tr key={item?.id || i}>
                  <td>{item?.title}</td>
                  <td>{item?.req_code}</td>

                  {moment(item?.start_date).isValid() ? moment(item?.start_date)?.format("DD-MMM-YYYY") : ""}
                  {moment(item?.start_date).isValid() ? moment(item?.start_date).isSame(item?.deadline_date, 'day')
                    ? ''
                    : ` to ${moment(item?.deadline_date)?.format("DD-MMM-YYYY")}` : ""}
                  {/* <td>{moment(item?.start_date)?.format("DD-MMM-YYYY")} to {moment(item?.deadline_date)?.format("DD-MMM-YYYY")}</td> */}
                  {/* <td>{moment(item?.deadline_date)?.format("DD-MMM-YYYY")}</td> */}
                  <td>{diff ? "Yes" : "No"}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    )}
  </div>
);
