import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal, ModalHeader, ModalBody, UncontrolledTooltip } from "reactstrap";

import Select from "react-select";
import ReactPaginate from "react-paginate";
import Spinner from "components/common/spinner";
// import { EditStatuarRequirment } from "./editStatuarRequirment";
// import { AddStatuaryRequirment } from "./addStatuaryRequirment";
import { ViewRequirment } from "./viewRequirment";
import { getPaginatedRequirmentData } from "../../../store/services/company.service";
import { DisplayFormsData } from "./displayFormsData";
import styled from "styled-components";
import Dropdown from "components/common/dropdown";
import { StatutoryForm1 } from "./StatutoryForm1";

import { AiTwotoneFilePdf } from "react-icons/ai";
import { StatutoryForm28 } from "./StatutoryForm28";
import { StatutoryForm3 } from "./StatutoryForm3";
import { StatutoryForm2 } from "./StatutoryForm2";
import { StatutoryForm3A } from "./StatutoryForm3A";
import { StatutoryForm45 } from "./statutoryForm45";
import { StatutoryForm26 } from "./StatutoryForm26";
import { StatutoryForm4 } from "./StatutoryForm4";
import { StatutoryForm5 } from "./StatutoryForm5";
import { StatutoryForm6 } from "./StatutoryForm6";
import { StatutoryForm4new } from "./StatutoryForm4new";
import { StatutoryForm8 } from "./StatutoryForm8";
import { StatutoryForm7 } from "./StatutoryForm7";
import { FaChevronRight } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
import { StatutoryForm7new } from "./StatutoryForm7new";
import { AppendixToForm3 } from "./appendixToForm3";
import { StatutoryFormA } from "./StatutoryFormA";
import { StatutoryForm10 } from "./StatutoryForm10";
import { StatutoryForm14 } from "./StatutoryForm14";
import { AppendixBToApp6 } from "./AppendixBToApp6";
import { StatutoryForm15 } from "./StatutoryForm15";
import { StatutoryForm16 } from "./StatutoryForm16";
import { StatutoryForm17 } from "./StatutoryForm17";
import { StatutoryForm13 } from "./StatutoryForm13";
import { StatutoryForm22 } from "./StatutoryForm22";
import { StatutoryForm23 } from "./StatutoryForm23";
import { StatutoryForm24 } from "./StatutoryForm24";
import { StatutoryForm25 } from "./StatutoryForm25";
import { AppendixToForm25 } from "./AppendixToForm25";
import { StatutoryForm27 } from "./StatutoryForm27";
import { Appendix1 } from "./Appendix1";
import { AppendixII } from "./AppendixII";
import { App2 } from "./App2";
import { App3 } from "./App3";
import { App5 } from "./App5";
import { AppendixToApp5 } from "./AppendixToApp5";
import { App6 } from "./App6";
import { App1 } from "./App1";
import { AppendixAtoApp6 } from "./AppendixAtoApp6";
import { StatutoryForm9 } from "./StatutoryForm9";
import { StatutoryForm11 } from "./StatutoryForm11";
import { StatutoryForm12 } from "./StatutoryForm12";
import { StatutoryForm18 } from "./StatutoryForm18";
import { StatutoryForm19 } from "./StatutoryForm19";
import { StatutoryForm20 } from "./StatutoryForm20";
import { StatutoryForm21 } from "./StatutoryForm21";
import { AppendixAtoApp3 } from "./AppendixAtoApp3";
import { AppendixBtoApp3 } from "./AppendixBtoApp3";
import { AppendixCtoApp3 } from "./AppendixCtoApp3";
import { App4 } from "./App4";
import { AppendixAtoApp4 } from "./AppendixAtoApp4";
import { AppendixBtoApp4 } from "./AppendixToBtoApp4";
import { AppendixCToApp4 } from "./AppendixCToApp4";
import { darkStyle } from "components/defaultStyles";


export default function StatuaryRequirments() {
  const mixLayout = useSelector((state) => state?.Customizer?.mix_layout);
  const onHoverClass = (mixLayout === "dark-body-only" || mixLayout === "dark-only" || mixLayout === "dark-sidebar-body-mix") ? "onHover-dark" : "onHover"
  const baseEmail = sessionStorage.getItem("email") || "";
  // Selector STARTS
  // const companies = useSelector((data) => data.Companies);
  // const features = useSelector((data) => data.Features).features;
  const [crudFeatures, setCrudFeatures] = useState([true, true, true, true]);
  const [companyCodeSearch, setCompanyCodeSearch] = useState(false);
  const [companyNameSearch, setCompanyNameSearch] = useState(true);
  const [parentCompanySearch, setParentCompanySearch] = useState(false);

  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [viewAddPage, setViewAddPage] = useState(false);
  const [viewAddRequirment, setViewAddRequirment] = useState(false);
  const [editRequirment, setEditRequirment] = useState(false);
  const [viewFlag, setViewFlag] = useState(false);
  const [searchedCompanies, setSearchedCompanies] = useState([]);
  const [company_options, setCompany_options] = useState([]);
  // new pagination server side
  const [currentPage, setCurrentPage] = useState();
  const [nextPage, setNextPage] = useState(1);
  const [prevPage, setPrevPage] = useState();
  const [hasNextPage, setHasNextPage] = useState();
  const [hasPrevPage, setHasPrevPage] = useState();
  const [totalPages, setTotalPages] = useState();
  const [criteria, setCriteria] = useState();
  const [totalRecords, setTotalRecords] = useState();
  const [showFormDesign, setShowFormDesign] = useState(false);

  const [formData, setFormData] = useState(null);
  // new pagination end

  let history = useHistory();
  /*  Pagination Code Start  */
  /*  ---------------------  */
  const [pageNumber, setPageNumber] = useState(0);
  const [companies, setCompanies] = useState([]);
  const [companies_dropdown, setCompanies_dropdown] = useState([]);
  const [isLoadingRequirment, setLoadingRequirment] = useState(false);
  const [statuaryRequirment, setStatuaryRequirments] = useState([]);
  const [form29Template, setForm29Template] = useState(false);
  const [formATemplate, setFormATemplate] = useState(false);
  const [form28Template, setForm28Template] = useState(false);
  const [form1Template, setForm1Template] = useState(false);
  const [form7newTemplate, setForm7newTemplate] = useState(false);
  const [form3Template, setForm3Template] = useState(false);
  const [form2Template, setForm2Template] = useState(false);
  const [form9Template, setForm9Template] = useState(false);
  const [form11Template, setForm11Template] = useState(false);
  const [form12Template, setForm12Template] = useState(false);
  const [form18Template, setForm18Template] = useState(false);
  const [form19Template, setForm19Template] = useState(false);
  const [form20Template, setForm20Template] = useState(false);
  const [form21Template, setForm21Template] = useState(false);
  const [form26Template, setForm26Template] = useState(false);
  const [form27Template, setForm27Template] = useState(false);
  const [app1Template, setApp1Template] = useState(false);
  const [appendix1Template, setAppendix1Template] = useState(false);
  const [appendixIITemplate, setAppendixIITemplate] = useState(false);
  const [app2Template, setApp2Template] = useState(false);
  const [app3Template, setApp3Template] = useState(false);
  const [app4Template, setApp4Template] = useState(false);
  const [app5Template, setApp5Template] = useState(false);
  const [app6Template, setApp6Template] = useState(false);
  const [appendixToApp5Template, setAppendixToApp5Template] = useState(false);
  const [appendixAToApp6Template, setAppendixAToApp6Template] = useState(false);
  const [appendixAToApp3Template, setAppendixAToApp3Template] = useState(false);
  const [appendixBToApp3Template, setAppendixBToApp3Template] = useState(false);
  const [appendixCToApp3Template, setAppendixCToApp3Template] = useState(false);
  const [appendixAToApp4Template, setAppendixAToApp4Template] = useState(false);
  const [appendixBToApp4Template, setAppendixBToApp4Template] = useState(false);
  const [appendixCToApp4Template, setAppendixCToApp4Template] = useState(false);
  const [form4Template, setForm4Template] = useState(false);
  const [form5Template, setStatutoryForm5Template] = useState(false);
  const [appendixtoForm3, setAppendixtoForm3] = useState(false);
  const [form6Template, setStatutoryForm6Template] = useState(false);
  const [form4newTemplate, setStatutoryForm4newTemplate] = useState(false);
  const [form8Template, setForm8Template] = useState(false);
  const [appendixBtoApp6Template, setAppendixBtoApp6Template] = useState(false);
  const [form10Template, setForm10Template] = useState(false);
  const [form7Template, setForm7Template] = useState(false);
  const [form13Template, setForm13Template] = useState(false);
  const [appendixToForm25Template, setAppendixToForm25Template] =
    useState(false);
  const [form25Template, setForm25Template] = useState(false);
  const [form24Template, setForm24Template] = useState(false);
  const [form23Template, setForm23Template] = useState(false);
  const [form22Template, setForm22Template] = useState(false);
  const [form14Template, setForm14Template] = useState(false);
  const [form15Template, setForm15Template] = useState(false);
  const [form16Template, setForm16Template] = useState(false);
  const [form17Template, setForm17Template] = useState(false);


  const getPaginatedRequirment = async () => {
    setLoadingRequirment(true);
    try {
      const response = await getPaginatedRequirmentData(
        baseEmail,
        "1",
        search ? search : "",
        criteria ? criteria : "",
      );
      if (response.status === 200) {
        setHasNextPage(response.data.data.hasNextPage);
        setHasPrevPage(response.data.data.hasPrevPage);
        setNextPage(response.data.data.nextPage);
        setPrevPage(response.data.data.prevPage);
        setCurrentPage(response.data.data.page);
        setTotalPages(response.data.data.totalPages);
        setTotalRecords(response.data.data.totalDocs);

        const parents = response.data.data.docs ? response.data.data.docs : [];

        setStatuaryRequirments(parents);
        setLoadingRequirment(false);
      }
    } catch (error) {
      setLoadingRequirment(false);
    }
  };

  useEffect(() => {
    getPaginatedRequirment();
  }, []);

  //new pagination
  const handleNextPage = async () => {
    setLoadingRequirment(true);
    try {
      //for paginated companies
      const response = await getPaginatedRequirmentData(
        baseEmail,
        nextPage,
        search ? search : "",
        criteria ? criteria : ""
      );
      if (response.status === 200) {
        setHasPrevPage(response.data.data.hasPrevPage);
        setNextPage(response.data.data.nextPage);
        setPrevPage(response.data.data.prevPage);
        setCurrentPage(response.data.data.page);
        setTotalPages(response.data.data.totalPages);
        setTotalRecords(response.data.data.totalDocs);

        const parents = response.data.data.docs ? response.data.data.docs : [];

        setStatuaryRequirments(parents);
        setLoadingRequirment(false);
      } else {
        return toast.error(response.data.message);
      }
    } catch (error) {
      setLoadingRequirment(false);
    }
  };

  const handlePrevPage = async () => {
    setLoadingRequirment(true);
    try {
      //for paginated companies
      const response = await getPaginatedRequirmentData(
        baseEmail,
        prevPage,
        search ? search : "",
        criteria ? criteria : ""
      );
      if (response.status === 200) {
        setHasPrevPage(response.data.data.hasPrevPage);
        setNextPage(response.data.data.nextPage);
        setPrevPage(response.data.data.prevPage);
        setCurrentPage(response.data.data.page);
        setTotalPages(response.data.data.totalPages);
        setTotalRecords(response.data.data.totalDocs);

        const parents = response.data.data.docs ? response.data.data.docs : [];

        setStatuaryRequirments(parents);
        setLoadingRequirment(false);
      } else {
        return toast.error(response.data.message);
      }
    } catch (error) {
      setLoadingRequirment(false);
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (criteria == "" || !criteria) {
      return toast.error("Please select search criteria!");
    }
    if (!search || search == "") {
      return toast.error("Enter value for searching");
    }
    let response;
    setLoadingRequirment(true);
    if (criteria !== "" && criteria) {
      response = await getPaginatedRequirmentData(
        baseEmail,
        "1",
        search,
        criteria
      );
      if (response.status === 200) {
        setHasPrevPage(response.data.data.hasPrevPage);
        setNextPage(response.data.data.nextPage);
        setPrevPage(response.data.data.prevPage);
        setCurrentPage(response.data.data.page);
        setTotalPages(response.data.data.totalPages);
        setTotalRecords(response.data.data.totalDocs);

        const parents = response.data.data.docs ? response.data.data.docs : [];

        setStatuaryRequirments(parents);
        setLoadingRequirment(false);
      } else {
        return toast.error(response.data.message);
      }
    }
  };

  const handlePageNumber = async (page_number) => {
    setLoadingRequirment(true);
    try {
      //for paginated companies
      const response = await getPaginatedRequirmentData(
        baseEmail,
        page_number,
        search ? search : "",
        criteria ? criteria : ""
      );
      if (response.status === 200) {
        setHasPrevPage(response.data.data.hasPrevPage);
        setNextPage(response.data.data.nextPage);
        setPrevPage(response.data.data.prevPage);
        setCurrentPage(response.data.data.page);
        setTotalPages(response.data.data.totalPages);
        setTotalRecords(response.data.data.totalDocs);

        const parents = response.data.data.docs ? response.data.data.docs : [];

        setStatuaryRequirments(parents);
        setLoadingRequirment(false);
      } else {
        return toast.error(response.data.message);
      }
    } catch (error) {
      setLoadingRequirment(false);
    }
  };

  const changePage = async (event) => {
    const page = event.selected + 1
    if (page - 1 === currentPage) {
      handleNextPage()
    } else if (page + 1 === currentPage) {
      handlePrevPage()
    } else {
      handlePageNumber(page)
    }
  }


  const displayStatuaryRequirmentPerPage = statuaryRequirment.map((item, i) => (
    <tr className={onHoverClass} key={i}>
      <td>{item?.company_type || ""}</td>
      <td>{item.title || ""}</td>
      <td>{item.secp_form || ""}</td>
      <td>{item?.level || ""}</td>
      <td>{item?.regulations || ""}</td>
      <td>{item?.frequency || ""}</td>
      {/* <td>{item?.notify_days}</td> */}
      <td>{item?.notify_days}</td>

      <td style={{ maxWidth: "80px" }}>
        <>
          <i
            className="fa fa-eye"
            style={{
              width: 35,
              fontSize: 16,
              padding: 11,
              color: "#4466F2",
              cursor: "pointer",
            }}
            id="RequirmentView"
            data-placement="top"
            onClick={() => {
              const obj = JSON.parse(JSON.stringify(item));
              setViewFlag(true);
              sessionStorage.setItem("selectedrequirment", JSON.stringify(obj));
            }}
          ></i>
          <UncontrolledTooltip placement="top" target="RequirmentView">
            {"View Requirement's Detail"}
          </UncontrolledTooltip>
        </>

        {/* <>
          <i
            className="fa fa-pencil"
            style={{
              width: 35,
              fontSize: 16,
              padding: 11,
              color: "#FF9F40",
              cursor: "pointer",
            }}
            id="companyEdit"
            data-placement="top"
            onClick={() => {
              const obj = JSON.parse(JSON.stringify(item));
              setEditRequirment(true);
              sessionStorage.setItem("selectedrequirment", JSON.stringify(obj));
            }}
          ></i>
          <UncontrolledTooltip placement="top" target="companyEdit">
            {"Edit Requirement's Detail"}
          </UncontrolledTooltip>
        </> */}
      </td>
    </tr>
  ));
  const ViewFormA = () => {
    setShowFormDesign(true);
    setFormATemplate(true);
  };
  const ViewForm1 = () => {
    setShowFormDesign(true);
    setForm1Template(true);
  };
  const ViewForm7new = () => {
    setShowFormDesign(true);
    setForm7newTemplate(true);
  };
  const ViewForm29 = () => {
    setShowFormDesign(true);
    setForm29Template(true);
  };

  const ViewForm28 = () => {
    setShowFormDesign(true);
    setForm28Template(true);
  };
  const ViewForm3 = () => {
    setShowFormDesign(true);
    setForm3Template(true);
  };
  const ViewForm2 = () => {
    setShowFormDesign(true);
    setForm2Template(true);
  };
  const ViewForm9 = () => {
    setShowFormDesign(true);
    setForm9Template(true);
  };
  const ViewForm11 = () => {
    setShowFormDesign(true);
    setForm11Template(true);
  };
  const ViewForm12 = () => {
    setShowFormDesign(true);
    setForm12Template(true);
  };
  const ViewForm18 = () => {
    setShowFormDesign(true);
    setForm18Template(true);
  };
  const ViewForm19 = () => {
    setShowFormDesign(true);
    setForm19Template(true);
  };
  const ViewForm20 = () => {
    setShowFormDesign(true);
    setForm20Template(true);
  };
  const ViewForm21 = () => {
    setShowFormDesign(true);
    setForm21Template(true);
  };
  const ViewForm26 = () => {
    setShowFormDesign(true);
    setForm26Template(true);
  };
  const ViewForm27 = () => {
    setShowFormDesign(true);
    setForm27Template(true);
  };
  const ViewApp1 = () => {
    setShowFormDesign(true);
    setApp1Template(true);
  };
  const ViewFormAppendix1 = () => {
    setShowFormDesign(true);
    setAppendix1Template(true);
  };
  const ViewFormAppendixII = () => {
    setShowFormDesign(true);
    setAppendixIITemplate(true);
  };
  const ViewFormApp2 = () => {
    setShowFormDesign(true);
    setApp2Template(true);
  };
  const ViewFormApp3 = () => {
    setShowFormDesign(true);
    setApp3Template(true);
  };
  const ViewFormApp4 = () => {
    setShowFormDesign(true);
    setApp4Template(true);
  };
  const ViewFormApp5 = () => {
    setShowFormDesign(true);
    setApp5Template(true);
  };
  const ViewFormApp6 = () => {
    setShowFormDesign(true);
    setApp6Template(true);
  };
  const ViewFormAppendixToApp5 = () => {
    setShowFormDesign(true);
    setAppendixToApp5Template(true);
  };
  const ViewFormAppendixAToApp6 = () => {
    setShowFormDesign(true);
    setAppendixAToApp6Template(true);
  };
  const ViewFormAppendixAToApp3 = () => {
    setShowFormDesign(true);
    setAppendixAToApp3Template(true);
  };
  const ViewFormAppendixBToApp3 = () => {
    setShowFormDesign(true);
    setAppendixBToApp3Template(true);
  };
  const ViewFormAppendixCToApp3 = () => {
    setShowFormDesign(true);
    setAppendixCToApp3Template(true);
  };
  const ViewFormAppendixAToApp4 = () => {
    setShowFormDesign(true);
    setAppendixAToApp4Template(true);
  };
  const ViewFormAppendixBToApp4 = () => {
    setShowFormDesign(true);
    setAppendixBToApp4Template(true);
  };
  const ViewFormAppendixCToApp4 = () => {
    setShowFormDesign(true);
    setAppendixCToApp4Template(true);
  };
  const ViewForm4 = () => {
    setShowFormDesign(true);
    setForm4Template(true);
  };
  const ViewForm5 = () => {
    setShowFormDesign(true);
    setStatutoryForm5Template(true);
  };
  const ViewAppendixToForm3 = () => {
    setShowFormDesign(true);
    setAppendixtoForm3(true);
  };
  const ViewForm6 = () => {
    setShowFormDesign(true);
    setStatutoryForm6Template(true);
  };
  const ViewForm4new = () => {
    setShowFormDesign(true);
    setStatutoryForm4newTemplate(true);
  };
  const ViewForm8 = () => {
    setShowFormDesign(true);
    setForm8Template(true);
  };
  const AppendixBtoApp6 = () => {
    setShowFormDesign(true);
    setAppendixBtoApp6Template(true);
  };
  const ViewForm10 = () => {
    setShowFormDesign(true);
    setForm10Template(true);
  };
  const ViewForm7 = () => {
    setShowFormDesign(true);
    setForm7Template(true);
  };
  const ViewForm13 = () => {
    setShowFormDesign(true);
    setForm13Template(true);
  };
  const ViewForm22 = () => {
    setShowFormDesign(true);
    setForm22Template(true);
  };
  const ViewForm23 = () => {
    setShowFormDesign(true);
    setForm23Template(true);
  };
  const ViewForm24 = () => {
    setShowFormDesign(true);
    setForm24Template(true);
  };
  const ViewForm25 = () => {
    setShowFormDesign(true);
    setForm25Template(true);
  };
  const ViewFormAppendixToForm25 = () => {
    setShowFormDesign(true);
    setAppendixToForm25Template(true);
  };
  const ViewForm14 = () => {
    setShowFormDesign(true);
    setForm14Template(true);
  };
  const ViewForm15 = () => {
    setShowFormDesign(true);
    setForm15Template(true);
  };
  const ViewForm16 = () => {
    setShowFormDesign(true);
    setForm16Template(true);
  };
  const ViewForm17 = () => {
    setShowFormDesign(true);
    setForm17Template(true);
  };

  const list = [
    {
      function: ViewFormA,
      title: "Form A",
    },
    {
      function: ViewForm1,
      title: "Form 1",
    },
    {
      function: ViewForm2,
      title: "Form 2",
    },
    {
      function: ViewForm3,
      title: "Form 3",
    },
    {
      function: ViewAppendixToForm3,
      title: "Appendix to From 3",
    },
    {
      function: ViewForm4new,
      title: "Form 4",
    },
    {
      function: ViewForm5,
      title: "Form 5",
    },
    {
      function: ViewForm6,
      title: "Form 6",
    },
    {
      function: ViewForm7new,
      title: "Form 7",
    },
    {
      function: ViewForm8,
      title: "Form 8",
    },
    {
      function: ViewForm9,
      title: "Form 9",
    },
    {
      function: ViewForm10,
      title: "Form 10",
    },
    {
      function: ViewForm11,
      title: "Form 11",
    },
    {
      function: ViewForm12,
      title: "Form 12",
    },
    {
      function: ViewForm13,
      title: "Form 13",
    },
    {
      function: ViewForm14,
      title: "Form 14",
    },
    {
      function: ViewForm15,
      title: "Form 15",
    },
    {
      function: ViewForm16,
      title: "Form 16",
    },
    {
      function: ViewForm17,
      title: "Form 17",
    },
    {
      function: ViewForm18,
      title: "Form 18",
    },
    {
      function: ViewForm19,
      title: "Form 19",
    },
    {
      function: ViewForm20,
      title: "Form 20",
    },
    {
      function: ViewForm21,
      title: "Form 21",
    },
    {
      function: ViewForm22,
      title: "Form 22",
    },
    {
      function: ViewForm23,
      title: "Form 23",
    },
    {
      function: ViewForm24,
      title: "Form 24",
    },
    {
      function: ViewForm25,
      title: "Form 25",
    },
    {
      function: ViewFormAppendixToForm25,
      title: "Appendix To Form 25",
    },
    {
      function: ViewForm26,
      title: "Form 26",
    },
    {
      function: ViewForm27,
      title: "Form 27",
    },
    {
      function: ViewFormAppendix1,
      title: "Appendix-1",
    },
    {
      function: ViewFormAppendixII,
      title: "Appendix-II",
    },
    {
      function: ViewApp1,
      title: "App 1",
    },
    {
      function: ViewFormApp2,
      title: "App 2",
    },
    {
      function: ViewFormApp3,
      title: "App 3",
    },
    {
      function: ViewFormAppendixAToApp3,
      title: "Appendix-A to App-3",
    },
    {
      function: ViewFormAppendixBToApp3,
      title: "Appendix-B to App-3",
    },
    {
      function: ViewFormAppendixCToApp3,
      title: "Appendix-C to App-3",
    },
    {
      function: ViewFormApp4,
      title: "App 4",
    },
    {
      function: ViewFormAppendixAToApp4,
      title: "Appendix-A to App-4",
    },
    {
      function: ViewFormAppendixBToApp4,
      title: "Appendix-B to App-4",
    },
    {
      function: ViewFormAppendixCToApp4,
      title: "Appendix-C to App-4",
    },
    {
      function: ViewFormApp5,
      title: "App 5",
    },
    {
      function: ViewFormAppendixToApp5,
      title: "Appendix to App-5",
    },
    {
      function: ViewFormApp6,
      title: "App 6",
    },
    {
      function: ViewFormAppendixAToApp6,
      title: "Appendix-A to App-6",
    },
    // {
    //   function: ViewForm29,
    //   title: "Form 29",
    // },

    // {
    //   function: ViewForm28,
    //   title: "Form 28",
    // },

    // {
    //   function: ViewForm4,
    //   title: "Form 4",
    // },
    // {
    //   function: ViewForm5,
    //   title: "Form 5",
    // },

    {
      function: AppendixBtoApp6,
      title: "Appendix-B to App-6",
    },

    // {
    //   function: ViewForm7,
    //   title: "Form 7",
    // },
  ];

  const listForSearch = [
    {
      label: "Form A",
      value: "Form A",
    },
    {
      label: "Form 1",
      value: "Form 1",
    },
    {
      label: "Form 2",
      value: "Form 2",
    },
    {
      label: "Form 3",
      value: "Form 3",
    },
    {
      label: "Appendix to From 3",
      value: "Appendix to From 3",
    },
    {
      label: "Form 4",
      value: "Form 4",
    },
    {
      label: "Form 5",
      value: "Form 5",
    },
    {
      label: "Form 6",
      value: "Form 6",
    },
    {
      label: "Form 7",
      value: "Form 7",
    },
    {
      label: "Form 8",
      value: "Form 8",
    },
    {
      label: "Form 9",
      value: "Form 9",
    },
    {
      label: "Form 10",
      value: "Form 10",
    },
    {
      label: "Form 11",
      value: "Form 11",
    },
    {
      label: "Form 12",
      value: "Form 12",
    },
    {
      label: "Form 13",
      value: "Form 13",
    },
    {
      label: "Form 14",
      value: "Form 14",
    },
    {
      label: "Form 15",
      value: "Form 15",
    },
    {
      label: "Form 16",
      value: "Form 16",
    },
    {
      label: "Form 17",
      value: "Form 17",
    },
    {
      label: "Form 18",
      value: "Form 18",
    },
    {
      label: "Form 19",
      value: "Form 19",
    },
    {
      label: "Form 20",
      value: "Form 20",
    },
    {
      label: "Form 21",
      value: "Form 21",
    },
    {
      label: "Form 22",
      value: "Form 22",
    },
    {
      label: "Form 23",
      value: "Form 23",
    },
    {
      label: "Form 24",
      value: "Form 24",
    },
    {
      label: "Form 25",
      value: "Form 25",
    },
    {
      label: "Appendix To Form 25",
      value: "Appendix To Form 25",
    },
    {
      label: "Form 26",
      value: "Form 26",
    },
    {
      label: "Form 27",
      value: "Form 27",
    },
    {
      label: "Appendix-1",
      value: "Appendix-1",
    },
    {
      label: "Appendix-II",
      value: "Appendix-II",
    },
    {
      label: "App 1",
      value: "App 1",
    },
    {
      label: "App 2",
      value: "App 2",
    },
    {
      label: "App 3",
      value: "App 3",
    },
    {
      label: "Appendix-A to App-3",
      value: "Appendix-A to App-3",
    },
    {
      label: "Appendix-B to App-3",
      value: "Appendix-B to App-3",
    },
    {
      label: "Appendix-C to App-3",
      value: "Appendix-C to App-3",
    },
    {
      label: "App 4",
      value: "App 4",
    },
    {
      label: "Appendix-A to App-4",
      value: "Appendix-A to App-4",
    },
    {
      label: "Appendix-B to App-4",
      value: "Appendix-B to App-4",
    },
    {
      label: "Appendix-C to App-4",
      value: "Appendix-C to App-4",
    },
    {
      label: "App 5",
      value: "App 5",
    },
    {
      label: "Appendix to App-5",
      value: "Appendix to App-5",
    },
    {
      label: "App 6",
      value: "App 6",
    },
    {
      label: "Appendix-A to App-6",
      value: "Appendix-A to App-6",
    },

    {
      label: "Appendix-B to App-6",
      value: "Appendix-B to App-6",
    },
  ];

  const tableWrapperRef = React.useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (tableWrapperRef.current) {
        const tableWidth = tableWrapperRef.current.scrollWidth;
        const wrapperWidth = tableWrapperRef.current.clientWidth;
        setIsScrollable(tableWidth > wrapperWidth);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!search || search === "") {
      getPaginatedRequirment()
    }
  }, [search])


  const [screen, setScreen] = useState();
  useEffect(() => {
    const handleResize = () => {
      setScreen(window?.outerWidth);
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Fragment>
      <div className="d-flex justify-content-between">
        <h6 className="text-nowrap mt-3 ml-3">Statutory Requirement Listing</h6>
        {/* <Breadcrumb title="Statutory Requirement Listing" parent="Statutory" /> */}
      </div>
      {/* Add Modal */}
      {/* <Modal isOpen={viewAddPage} show={viewAddPage.toString()} size="xl">
        <ModalHeader
          toggle={() => {
            setViewAddPage(false);
          }}
        >
          Add Company
        </ModalHeader>
        <ModalBody>
          <AddCompany setViewAddPage={setViewAddPage} />
        </ModalBody>
      </Modal> */}
      {/* alert code design */}

      {/* <Modal
        isOpen={viewAddRequirment}
        show={viewAddRequirment.toString()}
        size="xl"
      >
        <ModalHeader
          toggle={() => {
            setViewAddRequirment(false);
          }}
        >
          Add Statutory Requirement
        </ModalHeader>
        <ModalBody>
          <AddStatuaryRequirment
            getPaginatedRequirment={getPaginatedRequirment}
            setViewAddRequirment={setViewAddRequirment}
          />
        </ModalBody>
      </Modal> */
      }
      <Modal isOpen={showFormDesign} show={showFormDesign.toString()} size="xl">
        <ModalHeader
          toggle={() => {
            setShowFormDesign(false);
            setFormATemplate(false);
            setForm1Template(false);
            setForm2Template(false);
            setForm3Template(false);
            setAppendixtoForm3(false);
            setStatutoryForm4newTemplate(false);
            setStatutoryForm6Template(false);
            setForm7newTemplate(false);
            setForm9Template(false);
            setForm8Template(false);
            setForm10Template(false);
            setForm11Template(false);
            setForm12Template(false);
            setForm13Template(false);
            setForm14Template(false);
            setForm15Template(false);
            setForm16Template(false);
            setForm17Template(false);
            setForm18Template(false);
            setForm19Template(false);
            setForm20Template(false);
            setForm21Template(false);
            setForm22Template(false);
            setForm23Template(false);
            setForm24Template(false);
            setForm25Template(false);
            setAppendixToForm25Template(false);
            setForm26Template(false);
            setForm27Template(false);
            setAppendix1Template(false);
            setAppendixIITemplate(false);
            setApp1Template(false);
            setApp2Template(false);
            setApp3Template(false);
            setAppendixAToApp3Template(false);
            setAppendixBToApp3Template(false);
            setAppendixCToApp3Template(false);
            setApp4Template(false);
            setAppendixAToApp4Template(false);
            setAppendixBToApp4Template(false);
            setAppendixCToApp4Template(false);
            setApp5Template(false);
            setAppendixToApp5Template(false);
            setApp6Template(false);
            setAppendixAToApp6Template(false);
            setAppendixBtoApp6Template(false);
            // setForm4Template(false)
            // setStatutoryForm5Template(false)
            // setForm29Template(false)
            // setForm28Template(false)
          }}
        ></ModalHeader>
        <ModalBody>
          {form7newTemplate ? (
            <StatutoryForm7new formTemplate={form7newTemplate} />
          ) : form1Template ? (
            <StatutoryForm1 formTemplate={form1Template} />
          ) : form28Template ? (
            <StatutoryForm28 formTemplate={form28Template} />
          ) : form3Template ? (
            <StatutoryForm3 formTemplate={form3Template} />
          ) : form2Template ? (
            <StatutoryForm2 formTemplate={form2Template} />
          ) : form9Template ? (
            <StatutoryForm9 formTemplate={form9Template} />
          ) : form11Template ? (
            <StatutoryForm11 formTemplate={form11Template} />
          ) : form12Template ? (
            <StatutoryForm12 formTemplate={form12Template} />
          ) : form18Template ? (
            <StatutoryForm18 formTemplate={form18Template} />
          ) : form19Template ? (
            <StatutoryForm19 formTemplate={form19Template} />
          ) : form20Template ? (
            <StatutoryForm20 formTemplate={form20Template} />
          ) : form21Template ? (
            <StatutoryForm21 formTemplate={form21Template} />
          ) : form26Template ? (
            <StatutoryForm26 formTemplate={form26Template} />
          ) : form27Template ? (
            <StatutoryForm27 formTemplate={form27Template} />
          ) : appendix1Template ? (
            <Appendix1 formTemplate={appendix1Template} />
          ) : appendixIITemplate ? (
            <AppendixII formTemplate={appendixIITemplate} />
          ) : app1Template ? (
            <App1 formTemplate={app1Template} />
          ) : app2Template ? (
            <App2 formTemplate={app2Template} />
          ) : app3Template ? (
            <App3 formTemplate={app3Template} />
          ) : appendixAToApp3Template ? (
            <AppendixAtoApp3 formTemplate={appendixAToApp3Template} />
          ) : appendixBToApp3Template ? (
            <AppendixBtoApp3 formTemplate={appendixBToApp3Template} />
          ) : appendixCToApp3Template ? (
            <AppendixCtoApp3 formTemplate={appendixCToApp3Template} />
          ) : app4Template ? (
            <App4 formTemplate={app4Template} />
          ) : appendixAToApp4Template ? (
            <AppendixAtoApp4 formTemplate={appendixAToApp4Template} />
          ) : appendixBToApp4Template ? (
            <AppendixBtoApp4 formTemplate={appendixBToApp4Template} />
          ) : appendixCToApp4Template ? (
            <AppendixCToApp4 formTemplate={appendixCToApp4Template} />
          ) : app5Template ? (
            <App5 formTemplate={app5Template} />
          ) : app6Template ? (
            <App6 formTemplate={app6Template} />
          ) : appendixToApp5Template ? (
            <AppendixToApp5 formTemplate={appendixToApp5Template} />
          ) : appendixAToApp6Template ? (
            <AppendixAtoApp6 formTemplate={appendixAToApp6Template} />
          ) : form4Template ? (
            <StatutoryForm4 formTemplate={form4Template} />
          ) : form5Template ? (
            <StatutoryForm5 formTemplate={form5Template} />
          ) : form6Template ? (
            <StatutoryForm6 formTemplate={form6Template} />
          ) : appendixtoForm3 ? (
            <AppendixToForm3 formTemplate={appendixtoForm3} />
          ) : form4newTemplate ? (
            <StatutoryForm4new formTemplate={form4newTemplate} />
          ) : form8Template ? (
            <StatutoryForm8 formTemplate={form8Template} />
          ) : form13Template ? (
            <StatutoryForm13 formTemplate={form13Template} />
          ) : form22Template ? (
            <StatutoryForm22 formTemplate={form22Template} />
          ) : form23Template ? (
            <StatutoryForm23 formTemplate={form23Template} />
          ) : form24Template ? (
            <StatutoryForm24 formTemplate={form24Template} />
          ) : form25Template ? (
            <StatutoryForm25 formTemplate={form25Template} />
          ) : appendixToForm25Template ? (
            <AppendixToForm25 formTemplate={appendixToForm25Template} />
          ) : form14Template ? (
            <StatutoryForm14 formTemplate={form14Template} />
          ) : form15Template ? (
            <StatutoryForm15 formTemplate={form15Template} />
          ) : form16Template ? (
            <StatutoryForm16 formTemplate={form16Template} />
          ) : form17Template ? (
            <StatutoryForm17 formTemplate={form17Template} />
          ) : form7Template ? (
            <StatutoryForm7 formTemplate={form7Template} />
          ) : form10Template ? (
            <StatutoryForm10 formTemplate={form10Template} />
          ) : appendixBtoApp6Template ? (
            <AppendixBToApp6 formTemplate={appendixBtoApp6Template} />
          ) : formATemplate ? (
            <StatutoryFormA formTemplate={formATemplate} />
          ) : (
            <DisplayFormsData
              formATemplate={formATemplate}
              viewTemplate={form29Template}
              data={formData}
              setShowFormDesign={setShowFormDesign}
            />
          )}
        </ModalBody>
      </Modal>

      {/* alert code design */}
      {/* Edit Modal */}
      {/* <Modal isOpen={editRequirment} show={editRequirment.toString()} size="xl">
        <ModalHeader
          toggle={() => {
            setEditRequirment(false);
          }}
        >
          Statutory Requirement Edit
        </ModalHeader>
        <ModalBody>
          <EditStatuarRequirment
            getPaginatedRequirment={getPaginatedRequirment}
            setEditRequirment={setEditRequirment}
          />
        </ModalBody>
      </Modal> */}
      {/* View Modal */}
      <Modal
        style={{
          minWidth: screen <= 1199 ? "95%" : ""
        }}
        isOpen={viewFlag} show={viewFlag.toString()} size="xl">
        <ModalHeader
          toggle={() => {
            setViewFlag(false);
          }}
        >
          Statutory Requirement View
        </ModalHeader>
        <ModalBody>
          <ViewRequirment />
        </ModalBody>
      </Modal>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className={screen <= 500 ? "" : screen <= 922 ? "d-flex flex-wrap justify-content-start" : "d-flex flex-wrap justify-content-between"}>
                  <div className={screen <= 500 ? " px-0" : screen <= 1463 ? "d-flex justify-content-start pl-0 col-12" : "col-xl-8 col-lg-12 col-md-12 d-flex justify-content-start"} >
                    <form
                      className={screen <= 500 ? "col-12 px-0" : screen <= 922 ? "d-flex justify-content-start col-sm-10 px-0" : screen <= 1463 ? "d-flex justify-content-start col-sm-10 pl-0" : "d-flex justify-content-start col-lg-10 col-md-12 col-sm-12"}
                      onSubmit={(e) => {
                        handleSearch(e)
                      }}
                    >
                      <div className={screen <= 500 ? "col-12 pb-1" : screen <= 922 ? "col-5 pl-0" : screen <= 1463 ? "col-3 pl-0" : "col-xl-3 col-lg-3 col-md-3 col-sm-3"}>
                        <div className="form-group">
                          {/* <label htmlFor="company_type">Search Criteria</label> */}
                          <select
                            name="search_criteria"
                            className={`form-control`}
                            onChange={(e) => {
                              setSearch("");
                              setCriteria(e.target.value);

                            }}
                          >
                            <option value="">Select Criteria</option>
                            <option value="title">Title</option>
                            <option value="form">SECP Form Number</option>
                            {/* <option value="code">Code</option> */}
                          </select>
                        </div>
                      </div>
                      <div className={screen <= 500 ? "col-12 " : "col-xl-5 col-lg-5 col-md-4 col-sm-4"}>
                        {criteria !== "form" ? <div className="form-group">
                          <input
                            id="searchTransaction"
                            className="form-control"
                            type="text"
                            // placeholder={"Search Company"}
                            placeholder={
                              criteria == "" || !criteria
                                ? `Select Criteria`
                                : criteria == "form"
                                  ? `Search by SECP Form Number`
                                  : `Search by Requirement Title`

                            }
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            disabled={!criteria}
                          />
                        </div> : <div className="form-group">
                          <Select
                            options={listForSearch}
                            onChange={(selected) => {
                              !selected && setSearch("");
                              selected && setSearch(selected.value);
                            }}
                            isClearable={true}
                            styles={darkStyle}
                          />
                        </div>}
                      </div>

                      <div className="col-sm-2">
                        <div className="form-group">
                          <button
                            className={screen <= 500 ? "btn btn-secondary btn-sm my-1 col-12" : "btn btn-secondary btn-sm my-1"}
                            disabled={!criteria}
                          >
                            <span>{"Search"}</span>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className={screen <= 500 ? "" : "d-flex justify-content-end"}>
                    <div className={screen <= 500 ? "col-12 mb-2" : "btn-group mr-3"}>
                      <Dropdown
                        screen={screen <= 500 ? screen : ""}
                        button_color_class={"btn-success"}
                        header={"View Template"}
                        isRequirment={true}
                        list={list}
                        style={{ paddingTop: "7.5px", paddingBottom: "7px" }}
                      >
                        <i className="fa fa-file-excel-o mr-2"></i>
                      </Dropdown>
                    </div>

                    <div className={screen <= 500 ? "col-12 mt-3" : ""}>
                      {/* <> <button
                    id="viewform"
                        className="btn btn-primary btn-sm ml-2"
                        onClick={()=>{
                          setShowFormDesign(true)
                        }}
                      >
View Template
                        </button>
                        <UncontrolledTooltip  placement="top" target="viewform">
                {'View Form 29' }
              </UncontrolledTooltip></> */}
                      {/* <button
                        className={screen <= 500 ? "btn btn-primary btn-sm col-12 px-3" : "btn btn-primary btn-sm ml-2"}
                        onClick={() => {
                          setViewAddRequirment(true);
                        }}
                        style={{ paddingTop: "7.5px", paddingBottom: "7px" }}

                      >
                        Add Requirement
                      </button> */}
                      {/* )} */}
                    </div>
                  </div>
                </div>
              </div>
              {isLoadingRequirment === true && <Spinner />}
              {isLoadingRequirment === false &&
                statuaryRequirment.length !== 0 && (
                  <TableStyle>
                    <div
                      style={{
                        position: "relative",
                      }}
                      className="px-3 pb-3"
                    >
                      {isScrollable && (
                        <ScrollIconStyle>
                          <FaChevronLeft
                            style={{
                              position: "absolute",
                              left: "1px",
                              bottom: "11px",
                              color: "grey",
                              cursor: "pointer",
                            }}
                            className="left_Icon"
                          />
                        </ScrollIconStyle>
                      )}
                      <div ref={tableWrapperRef} className="table-responsive">
                        <TableWrapper className="table table table-striped ">
                          <thead>
                            <tr>
                              <th>Company Type</th>
                              <th>Title </th>
                              <th>SECP FORM</th>
                              <th>Level</th>
                              <th>Regulation</th>
                              <th>Frequency </th>
                              <th>Notify Days </th>
                              {/* <th>Notify Days </th> */}
                              <th style={{ maxWidth: "80px" }}>Action</th>
                            </tr>
                          </thead>

                          <tbody>{displayStatuaryRequirmentPerPage}</tbody>
                        </TableWrapper>
                        <center className="d-flex justify-content-center py-3">
                          <nav className="pagination">
                            <ReactPaginate
                              previousLabel="Previous"
                              nextLabel="Next"
                              pageCount={totalPages}
                              onPageChange={changePage}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={6}
                              containerClassName={"pagination"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              disabledClassName={"disabled"}
                              pageLinkClassName={"page-link"}
                              pageClassName={"page-item"}
                              activeClassName={"page-item active"}
                              activeLinkClassName={"page-link"}
                              renderOnZeroPageCount={null}
                              forcePage={currentPage - 1}
                            />
                            {/* {hasPrevPage && (
                              <button
                                className="btn btn-primary btn-sm mx-1"
                                onClick={() => getPaginatedRequirment(prevPage)}
                              >
                                <span>{"Prev"}</span>
                              </button>
                            )}
                            {hasNextPage && (
                              <button
                                className="btn btn-secondary btn-sm mx-1"
                                onClick={() => getPaginatedRequirment(nextPage)}
                              >
                                <span>{"Next"}</span>
                              </button>
                            )} */}
                          </nav>
                        </center>
                        <p className="align-content-center text-center mx-2">
                          Page {currentPage} of {totalPages}
                        </p>
                        <p className="text-right mx-2">
                          {totalRecords} Records
                        </p>
                      </div>
                      {isScrollable && (
                        <ScrollIconStyle>
                          <FaChevronRight
                            style={{
                              position: "absolute",
                              right: "1px",
                              bottom: "11px",
                              color: "grey",
                              cursor: "pointer",
                            }}
                            className="right_Icon"
                          />
                        </ScrollIconStyle>
                      )}
                    </div>
                  </TableStyle>
                )}
              {isLoadingRequirment === false &&
                statuaryRequirment.length === 0 && (
                  <p className="text-center">
                    <b>Statutory Requeriment Data not available</b>
                  </p>
                )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
const ScrollIconStyle = styled.div`
  .left_Icon:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .right_Icon:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
const TableWrapper = styled.table`
  .table td::nth-last-child(8) {
    max-width: 80px !important;
  }
`;
const TableStyle = styled.div`
.onHover:hover{
  background-color:#ecf3f8 !important
}
  .onHover-dark:hover{
  background-color:#313131 !important
  }

`;
