import React, { useState, useEffect, useRef } from "react";
import * as _ from "lodash";
import { Eye, Send } from "react-feather";
import "./adminDashboard.css";
import { AiFillNotification } from "react-icons/ai";
import { getTransactionsAllRequests } from "../../store/services/transaction.service";
import Select from "react-select";
import loader from "../../assets/images/dcc_loader.svg";
import { BsCheckLg } from "react-icons/bs";

import moment from "moment";
import { darkStyle } from "components/defaultStyles";
import {
  getCompanyById,
  getElectronicPhysicalCountByCompanyCode,
  getEventDataByCompanyCode,
} from "../../store/services/company.service";
import { getInvestorRequestByCompanyCodeService } from "../../store/services/investor.service";
import { getTransactionTypes } from "../../store/services/transaction.service";

import { getDashboardCounters } from "../../store/services/adminDashboard.service";
import ReactEcharts from "echarts-for-react";
import { IsJsonString, numberWithCommas } from "utilities/utilityFunctions";
import { toast } from "react-toastify";
import {
  FaRegNewspaper,
  FaUsers,
  FaTag,
  FaHive,
  FaChartPie,
  FaCalendarAlt,
  FaCalendarCheck,
  FaDatabase,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";
import { Chart } from "react-google-charts";

import { getCorporateActions } from "../../store/services/corporate.service";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Spinner } from "reactstrap";
// import { getDashboardGraphData } from "src/store/services/adminDashboard.service.js";
import { getDashboardGraphData } from "../../store/services/adminDashboard.service";
import { Modal, ModalHeader, ModalBody, UncontrolledTooltip } from "reactstrap";
import ShareholderListing from "components/shareRegistrar/shareholders/shareholderListing";
import MyCalendar from "components/shareRegistrar/calendar/MyCalendar";
import { ViewEvent } from "components/shareRegistrar/Staturtory/viewEvent";

const AdminDashboard = () => {
  const history = useHistory();

  const baseEmail = sessionStorage.getItem("email");
  const [baseCompany, setBaseCompany] = useState(
    sessionStorage.getItem("company_code")
  );
  const [companySelected, setCompanySelected] = useState(false);
  const [dashboard_data, setDashboard_Data] = useState([]);
  const [company_governance, setCompanyGovernance] = useState([]);
  let [company_data, setCompany_Data] = useState([]);
  const [company_data_loading, setCompany_Data_Loading] = useState(false);
  const [showCompanyGoveranceFullData, setShowCompanyGoveranceData] =
    useState(false);
  const [announcement_data, setAnnouncement_Data] = useState([]);
  const [investor_request_data, setInvestor_Request_Data] = useState([]);
  const [transaction_request_types, setTransaction_Request_Types] = useState(
    []
  );
  const [physicalShares, setPhysicalShares] = useState();
  const [electronicShares, setElectronicShares] = useState();
  const [investorRequest, setInvestorRequest] = useState([]);
  const [completedTxns, setCompletedTxns] = useState([]);
  const [
    transaction_request_data_loading,
    setTransaction_Request_Data_Loading,
  ] = useState(false);
  const [graphData, setGraphData] = useState(null);
  const [graphDataLoading, setGraphDataLoading] = useState(false);
  const [auditor, setAuditor] = useState("");
  const [nextBoardElection, setNextBoardElection] = useState("");
  const [freefloatPer, setFreeFloatPer] = useState(null);
  const [freefloat, setFreeFloat] = useState(null);
  const [keyExective, setKeyExective] = useState(null);
  const [loading, setLoading] = useState(false);
  const [boardDirectors, setBoardDirectors] = useState([]);
  const [chartColor] = useState([]);
  const [cardData, setCardData] = useState([]);

  const [eventData, setEventData] = useState([]);
  const scrollRef = useRef(null);
  const [viewEvent, setViewEvent] = useState(false);

  const handleScrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft -= screen <= 500 ? 180 : 200; // Scroll left by 200px
    }
  };

  const handleScrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += screen <= 500 ? 180 : 200; // Scroll right by 200px
    }
  };

  const dummyChartColor = [
    "#3366CC",
    "#CC3300",
    "#FF9900",
    "#009900",
    "#990099",
    "#0099CC",
    "#CC3366",
    "#669900",
    "#336699",
    "#993399",
    "#009999",
    "#999900",
    "#6633CC",
    "#CC6600",
    "#990000",
    "#669900",
    "#FF6633",
    "#FFB399",
    "#FF33FF",
    "#FFFF99",
    "#95A6D1",
    "#6581C7",
    "#CFCF90",
    "#B2B9B2",
    "#ACC7AC",
  ];
  const boardElectionData = new Date(company_data?.board_election_date);
  const [selectedCompanyData, setSelectedCompanyData] = useState({});
  const [totalHolders, setTotalHolders] = useState(0);
  const [totalShares, setTotalShares] = useState(0);
  const [filter, setFilter] = useState("");
  const [viewListingModal, setViewListingModal] = useState(false);
  const [criteriaParam, setCriteriaParam] = useState("");
  const [searchParam, setSearchParam] = useState("");

  const dashboardFunctionality = async () => {
    setLoading((prevState) => true);
    setCompanySelected((prevState) => true);

    let response;
    let response2;
    let response3;
    let response4;
    let response5;
    let response6;
    let response7;
    let response8;

    let tempArr = [];
    let percentage;

    let findingCompany = {};
    try {
      response = await getCompanyById(baseEmail, baseCompany);
      findingCompany = response.data.data;
      setCompany_Data(response.data.data);
      setCompanyGovernance(
        JSON.parse(response?.data?.data?.governance || "[]")
      );

      //fix
      if (response?.data?.data?.service_providers) {
        const ServiceProviderParseData = JSON.parse(
          company_data?.service_providers
            ? response?.data?.data?.service_providers
            : "[]"
        );

        const getAuditor =
          ServiceProviderParseData &&
          typeof ServiceProviderParseData !== "string" &&
          ServiceProviderParseData.length > 0
            ? ServiceProviderParseData?.find((item) => item?.type == "Auditor")
                ?.auditor
            : "";
        setAuditor(getAuditor);
      }
      if (response?.data?.data?.next_board_election_date)
        setNextBoardElection(response?.data?.data?.next_board_election_date);
      const total = parseInt(response?.data?.data?.outstanding_shares || "0");

      if (response?.data?.data?.free_float) {
        setFreeFloat(parseInt(response?.data?.data?.free_float));
        percentage =
          (parseInt(response?.data?.data?.free_float || "0") / total) * 100;
        if (
          parseInt(response?.data?.data?.free_float || "0") === 0 ||
          total === 0
        ) {
          setFreeFloatPer(0);
        } else {
          setFreeFloatPer(percentage);
        }
      } else {
        setFreeFloat(null);
        setFreeFloatPer(null);
      }
    } catch (error) {
      toast.error("Error fetching company information!");
    }

    try {
      response2 = await getDashboardCounters(email, baseCompany);
      if (response2.status == 200) {
        if (response2.data.data.corporateTransactions.length > 5) {
          response2.data.data.corporateTransactions.length = 5;
        }
        setDashboard_Data(response2.data.data);
      } else {
        setDashboard_Data([]);
      }
    } catch (error) {
      toast.error("Error fetching dashboard data!");
    }

    try {
      response3 = await getInvestorRequestByCompanyCodeService(
        email,
        // selectedCompany
        baseCompany
      );
      if (response3.status == 200) {
        setInvestor_Request_Data(response3.data.data);
      } else {
        setInvestor_Request_Data([]);
      }
    } catch (error) {
      toast.error("Error fetching investor request data!");
    }

    try {
      response4 = await getTransactionTypes(email);
      if (response4.status == 200) {
        const options = response4.data.data.map((item) => {
          return { label: item.transactionName, value: item.transactionCode };
        });
        setTransaction_Request_Types(options);
      } else {
        setTransaction_Request_Types([]);
      }
    } catch (error) {}

    try {
      response5 = await getCorporateActions(email, baseCompany);
      if (response5.status == 200) {
        setAnnouncement_Data(response5.data.corporateActions);
      } else {
        setAnnouncement_Data([]);
      }
    } catch (error) {}

    try {
      response6 = await getTransactionsAllRequests(email, baseCompany);
      if (response6.status == 200) {
        const concateTwoArray = response6.data.pending.concat(
          response6.data.rejected
        );
        const sortedArray = concateTwoArray.sort(
          // (a, b) => new Date(b.created_at) - new Date(a.created_at)
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
        // sortedArray.length = 5;
        setInvestorRequest(sortedArray.slice(0, 5));

        setCompletedTxns(response6.data.completed);
      } else {
        setInvestorRequest([]);
        setCompletedTxns([]);
      }
    } catch (error) {}

    try {
      response7 = await getElectronicPhysicalCountByCompanyCode(
        email,
        baseCompany
      );
      if (response7.data.status == 404) {
        toast.error("No shareholder in this company.");
      }
      if (response7.status == 200) {
        setPhysicalShares(response7.data.data.physicalShares);
        setElectronicShares(response7.data.data.electronicShares);
      } else {
        toast.error("Error fetching share information!");
      }
    } catch (error) {}

    try {
      response8 = await getEventDataByCompanyCode(email, baseCompany);
      if (response8.status === 200 && response8.data.status == 200) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        response8.data.data = response8.data.data
          .sort((a, b) => {
            const dateA = new Date(a.start_date);
            const dateB = new Date(b.start_date);
            return dateA.getTime() - dateB.getTime();
          })
          .filter((event) => {
            const eventDate = new Date(event.start_date);
            return eventDate.getTime() >= today.getTime();
          });
        setEventData(response8.data.data);
      } else {
        setEventData([]);
      }
    } catch (error) {
      console.log("Error fetching event data!");
    }

    // getSatuatoryEventsByCompany(findingCompany);
    setSelectedCompanyData(findingCompany);

    if (IsJsonString(findingCompany?.governance)) {
      const gov = JSON.parse(findingCompany?.governance || "[]");
      let finalGov = [];
      let finalExec = [];
      for (let i = 0; i < gov.length; i++) {
        let c = gov[i];
        if (
          c.role == "CEO" ||
          c.role == "Company Secretary" ||
          c.role == "Chairman" ||
          c.role == "CFO" ||
          c.role == "COO"
        ) {
          finalExec.push(c);
        } else if (
          // c.role == "Director" ||
          // c.role == "Chairman/Non-Ex-Director" ||
          // c.role == "Chairman/Independent-Director" ||
          // c.role == "Shareholder/Non-Ex-Director" ||
          // c.role == "Shareholder/Independent-Director"
          c.role.includes("Director")
        ) {
          finalGov.push(c);
        }
      }
      setBoardDirectors((prevState) => finalGov);
      setKeyExective((prevState) => finalExec);
    } else {
      const gov = findingCompany?.governance ? findingCompany?.governance : [];
      let finalGov = [];

      let finalExec = [];
      for (let i = 0; i < gov.length; i++) {
        let c = gov[i];
        if (
          c.role == "CEO" ||
          c.role == "Company Secretary" ||
          c.role == "Chairman" ||
          c.role == "CFO" ||
          c.role == "COO"
        ) {
          finalExec.push(c);
        } else if (
          // c.role == "Director" ||
          // c.role == "Chairman/Non-Ex-Director" ||
          // c.role == "Chairman/Independent-Director" ||
          // c.role == "Shareholder/Non-Ex-Director" ||
          // c.role == "Shareholder/Independent-Director"
          c.role.includes("Director")
        ) {
          finalGov.push(c);
        }
      }

      setBoardDirectors((prevState) => finalGov);
      setKeyExective((prevState) => finalExec);
    }

    if (
      response?.data?.data &&
      response?.data?.data?.outstanding_shares &&
      parseInt(response?.data?.data?.outstanding_shares || "0") !== 0
    ) {
      tempArr.push({
        id: 1,
        icon: <FaRegNewspaper size={40} />,
        title: "Outstanding Shares",
        value: numberWithCommas(response?.data?.data?.outstanding_shares),
        background: "",
        className: "bg-secondary",
        textClass: "",
      });
    }
    if (
      response2?.data?.data &&
      response2?.data?.data?.shareholderCount &&
      parseInt(response2?.data?.data?.shareholderCount || "0") !== 0
    ) {
      tempArr.push({
        id: 2,
        icon: <FaUsers className="text-white" size={40} />,
        title: "Shareholders",
        value: numberWithCommas(response2?.data?.data?.shareholderCount),
        background: "#ff8309",
        className: "",
        textClass: "text-white",
      });
    }
    if (
      response?.data?.data &&
      response?.data?.data?.face_value &&
      parseInt(response?.data?.data?.face_value || "0") !== 0
    ) {
      tempArr.push({
        id: 3,
        icon: <FaTag className="text-white" size={40} />,
        title: "Face Value",
        value: numberWithCommas(response?.data?.data?.face_value),
        background: "#999999c9",
        className: "",
        textClass: "text-white",
      });
    }
    if (percentage && percentage !== 0 && percentage !== Infinity) {
      tempArr.push({
        id: 4,
        icon: <FaHive className="text-white" size={40} />,
        title: "Free Float %",
        value: numberWithCommas(percentage.toFixed(2)),
        background: "rgb(255 214 15)",
        className: "",
        textClass: "text-white",
      });
    }
    if (
      response2?.data?.data &&
      response2?.data?.data?.filerCount &&
      parseInt(response2?.data?.data?.filerCount || "0") !== 0
    ) {
      tempArr.push({
        id: 5,
        icon: <FaUsers className="text-white" size={40} />,
        title: "Filer",
        value: numberWithCommas(response2?.data?.data?.filerCount),
        background: "#8496B0",
        className: "",
        textClass: "text-white",
      });
    }
    if (
      response2?.data?.data &&
      response2?.data?.data?.nonFilerCount &&
      parseInt(response2?.data?.data?.nonFilerCount || "0") !== 0
    ) {
      tempArr.push({
        id: 6,
        icon: <FaUsers className="text-white" size={40} />,
        title: "Non Filer",
        value: numberWithCommas(response2?.data?.data?.nonFilerCount),
        background: "#EF7564",
        className: "",
        textClass: "text-white",
      });
    }
    if (
      response2?.data?.data &&
      response2?.data?.data?.zakatCount &&
      parseInt(response2?.data?.data?.zakatCount || "0") !== 0
    ) {
      tempArr.push({
        id: 6,
        icon: <FaTag className="text-white" size={40} />,
        title: "Zakat",
        value: numberWithCommas(response2?.data?.data?.zakatCount),
        background: "#5BA4CF",
        className: "",
        textClass: "text-white",
      });
    }
    if (
      response2?.data?.data &&
      response2?.data?.data?.zakatExemptedCount &&
      parseInt(response2?.data?.data?.zakatExemptedCount || "0") !== 0
    ) {
      tempArr.push({
        id: 6,
        icon: <FaHive className="text-white" size={40} />,
        title: "Zakat Exempt",
        value: numberWithCommas(response2?.data?.data?.zakatExemptedCount),
        background: "#595959",
        className: "",
        textClass: "text-white",
      });
    }

    setCardData(tempArr);
    setLoading((prevState) => false);
  };

  useEffect(() => {
    const handleStorageChange = () => {
      // window.location.reload();
      setBaseCompany(sessionStorage.getItem("company_code"));
      // dashboardFunctionality();
      // getShareHolerGraphData();
      return;
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    // if (sessionStorage.getItem("company_code")) {
    dashboardFunctionality();
    getShareHolerGraphData();
    // } else {
    //   setCompanySelected(false);
    //   toast.error("Select Company in Header!");
    // }
  }, [baseCompany]);

  const getColor = (index) => {
    chartColor.push(dummyChartColor[index]);
  };
  const getShareHolerGraphData = async () => {
    try {
      setGraphDataLoading(true);
      const response = await getDashboardGraphData(email, baseCompany);

      if (response.status == 200 && response.data.status === 200) {
        const categories = Object.values(response.data.data).filter(
          (value) => typeof value === "object" && value !== null
        );

        const dummyArray = [["Category", "SHARES"]];

        categories.map((item) => dummyArray.push([item.name, item.shares]));
        setTotalShares((prev) => response.data.data.total_shares);
        setTotalHolders((prev) => response.data.data.total_holders);

        setGraphData((prev) => dummyArray);
      } else {
        setGraphData([]);
      }
    } catch (error) {
      setGraphDataLoading(false);
      if (error.response != undefined) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }

    setGraphDataLoading(false);
  };

  const email = sessionStorage.getItem("email");

  const options = {
    is3D: true,
    pieSliceText: "none",
    backgroundColor: { fill: "transparent" },
    // width: 400,
    // height: 600,
    tooltip: {
      isHtml: true,
      animation: true,
      trigger: "focus",
      innerHeight: "10px",
      outerHeight: "10px",
    },
    chartArea: {
      left: "center",
      top: "center",
      // right: 70,
      width: "100% ",
      height: "80%",
    },
    sliceVisibilityThreshold: 0,
    legend: "none",
    colors: [
      "#3366CC",
      "#CC3300",
      "#FF9900",
      "#009900",
      "#990099",
      "#0099CC",
      "#CC3366",
      "#669900",
      "#336699",
      "#993399",
      "#009999",
      "#999900",
      "#6633CC",
      "#CC6600",
      "#990000",
      "#669900",
      "#FF6633",
      "#FFB399",
      "#FF33FF",
      "#FFFF99",
      "#95A6D1",
      "#6581C7",
      "#CFCF90",
      "#B2B9B2",
      "#ACC7AC",
    ],
  };

  const option = {
    series: [
      {
        type: "gauge",
        center: ["50%", "60%"],
        startAngle: 180,
        endAngle: 0,
        splitNumber: 10,
        itemStyle: {
          color: "#121212",
          background: "#121212",
        },
        progress: {
          show: true,
          roundCap: true,
          width: 2,
        },
        pointer: {
          icon: "path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z",
          length: "75%",
          width: 6,
          offsetCenter: [0, "-8%"],
        },
        axisLine: {
          roundCap: true,
          lineStyle: {
            width: 3,
            color: [[1, "#121212"]],
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          distance: -1,
          length: 3,
          show: true,
          lineStyle: {
            show: true,
            width: 2,
            color: "black",
          },
        },
        axisLabel: {
          distance: 8,
          show: false,
          itemStyle: {
            color: "#121212",
          },
          color: "#121212",
          fontSize: 8,
        },
        anchor: {
          show: false,
        },
        title: {
          show: true,
        },
        detail: {
          show: false,
        },
        data: [
          {
            value:
              (electronicShares / (electronicShares + physicalShares)) * 100,
          },
        ],
      },
    ],
  };

  const [screen, setScreen] = useState();
  useEffect(() => {
    const handleResize = () => {
      setScreen(window?.outerWidth);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {viewEvent && (
        <Modal
          toggle={() => {
            setViewEvent(false);
            sessionStorage.removeItem("tempRequirementDetails");
          }}
          isOpen={viewEvent}
          show={viewEvent.toString()}
          size="xl"
        >
          <ModalHeader
            toggle={() => {
              setViewEvent(false);
              sessionStorage.removeItem("tempRequirementDetails");
            }}
          >
            View Event
          </ModalHeader>
          <ModalBody>
            <ViewEvent showID={true} />
          </ModalBody>
        </Modal>
      )}
      {/* Shareholder listing modal */}
      <Modal
        toggle={() => {
          setViewListingModal(false);
        }}
        isOpen={viewListingModal}
        show={viewListingModal.toString()}
        size="xl"
      >
        <ModalHeader
          toggle={() => {
            setViewListingModal(false);
          }}
        >
          {filter} Shareholders
        </ModalHeader>
        <ModalBody>
          <div>
            <ShareholderListing
              dashboard={true}
              searchParam={searchParam}
              criteriaParam={criteriaParam}
            />
          </div>
        </ModalBody>
      </Modal>
      {companySelected && !loading ? (
        <div className="col-12">
          {baseCompany && (
            <>
              <div className="row align-items-center d-flex justify-content-between">
                <div className="col-md-9">
                  <WrapperHeader>
                    <div className=" row ">
                      <div>
                        <div className="d-flex align-items-center image-margin-onSmall col-md-12 ml-3 mt-3">
                          {company_data?.logo && (
                            <img
                              style={{
                                maxWidth: "200px",
                                width: "fit-content",
                                height: "100px",
                                objectFit: "contain",
                                objectPosition: "center",
                              }}
                              src={company_data?.logo}
                            />
                          )}
                          <div
                            className="d-flex flex-column text-start ml-2"
                            style={{
                              fontSize: "20px",
                              // maxWidth: "200px",
                              width: "fit-content",
                              lineHeight: "normal",
                            }}
                          >
                            {company_data.company_name}
                            <span
                              style={{
                                lineHeight: "normal",
                                fontSize: "15px",
                                color: "grey",
                                opacity: 0.8,
                              }}
                            >
                              {company_data?.symbol
                                ? company_data?.symbol
                                : company_data?.company_type}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </WrapperHeader>
                </div>
                {screen > 500 && (
                  <Wrapper className="col-md-3">
                    <ReactEcharts
                      style={{ width: "60px", height: "100px" }}
                      option={option}
                    />
                    <div className="mt-1" style={{ fontSize: "20px" }}>
                      DASHBOARD
                    </div>
                  </Wrapper>
                )}
              </div>

              <div className="container-fluid">
                <div className="d-flex flex-wrap">
                  {cardData &&
                    cardData.map((item) => (
                      <div
                        className="col-12 col-md-6 col-lg-3 mt-3"
                        key={item.id}
                        style={
                          item.title === "Shareholders" ||
                          item.title === "Non Filer"
                            ? { cursor: "pointer" }
                            : {}
                        }
                        onClick={() => {
                          if (item.title === "Shareholders") {
                            history.push("/shareholder-listing");
                          }
                          if (item.title === "Non Filer") {
                            setFilter("Non FIler");
                            setCriteriaParam("filer");
                            setSearchParam("N");
                            setViewListingModal(true);
                          }
                          if (item.title === "Filer") {
                            setFilter("Filer");
                            setCriteriaParam("filer");
                            setSearchParam("Y");
                            setViewListingModal(true);
                          }
                          if (item.title === "Zakat") {
                            setFilter("Zakat");
                            setCriteriaParam("zakat_status");
                            setSearchParam("N");
                            setViewListingModal(true);
                          }
                          if (item.title === "Zakat Exempt") {
                            setFilter("Zakat Exempt");
                            setCriteriaParam("zakat_status");
                            setSearchParam("Y");
                            setViewListingModal(true);
                          }
                        }}
                      >
                        <div className="card o-hidden shadow">
                          <div
                            style={{ background: item.background }}
                            className={`${item.className} b-r-4 card-body`}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div>{item.icon}</div>
                              <div
                                style={{
                                  maxWidth: "78%",
                                  width: "auto",
                                }}
                              >
                                <div className="media-body">
                                  <span className={`${item.textClass} m-0`}>
                                    {item.title}
                                  </span>
                                  <h4
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={item?.value}
                                    style={{
                                      width: "100%",
                                      //   display: "flex",
                                      //   justifyContent: "flex-end",
                                    }}
                                    className={`${item.textClass} mb-0 counter text-right text-truncate`}
                                  >
                                    {item.value}
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              {/* Shares Title */}

              <div className="container-fluid">
                <div className="page-header " style={{ marginTop: "-25px" }}>
                  <div className="row">
                    <div className="col">
                      {screen > 500 && (
                        <WrapperSharesHeader>
                          <div className="page-header-left d-flex">
                            <ReactEcharts
                              style={{ width: "60px", height: "60px" }}
                              option={option}
                            />
                            <h3>Shares</h3>
                          </div>
                        </WrapperSharesHeader>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* End Shares Title */}

              <div className="container-fluid">
                <ShareWrapperForResponsive>
                  <div className="row responsive onSmall">
                    {electronicShares &&
                    electronicShares !== 0 &&
                    electronicShares !== "" ? (
                      <div className="col-md-2">
                        <span>Electronic Shares</span>
                        <h5
                          data-toggle="tooltip"
                          data-placement="top"
                          title={numberWithCommas(electronicShares)}
                          className="text-truncate w-100"
                        >
                          {numberWithCommas(electronicShares)}
                        </h5>
                      </div>
                    ) : null}

                    {physicalShares &&
                    physicalShares !== 0 &&
                    physicalShares !== "" ? (
                      <div className="col-md-2">
                        <span>Physical Shares </span>
                        <h5
                          data-toggle="tooltip"
                          data-placement="top"
                          title={numberWithCommas(physicalShares)}
                          className="text-truncate w-100"
                        >
                          {numberWithCommas(physicalShares)}
                        </h5>
                      </div>
                    ) : null}

                    {/* {company_data?.outstanding_shares &&
                    parseInt(company_data?.outstanding_shares || "0") !== 0 ? (
                    <div className="col-md-2">
                      <span>Outstanding</span>
                      <h5>
                        {company_data.outstanding_shares === ""
                          ? "0"
                          : numberWithCommas(company_data.outstanding_shares)}
                      </h5>
                    </div>
                  ) : null} */}

                    {company_data?.free_float &&
                    parseInt(company_data?.free_float || "0") !== 0 ? (
                      <div className="col-md-2">
                        <span>Free Float</span>
                        <h5
                          data-toggle="tooltip"
                          data-placement="top"
                          title={numberWithCommas(company_data.free_float)}
                          className="text-truncate w-100"
                        >
                          {company_data.free_float === ""
                            ? "0"
                            : numberWithCommas(company_data.free_float)}
                        </h5>
                      </div>
                    ) : null}

                    {company_data?.treasury_shares &&
                    parseInt(company_data?.treasury_shares || "0") !== 0 ? (
                      <div className="col-md-2">
                        <span>Treasury</span>
                        <h5
                          data-toggle="tooltip"
                          data-placement="top"
                          title={numberWithCommas(company_data.treasury_shares)}
                          className="text-truncate w-100"
                        >
                          {company_data.treasury_shares === ""
                            ? "0"
                            : company_data.treasury_shares}
                        </h5>
                      </div>
                    ) : null}

                    {/* {company_data?.ordinary_shares &&
                    parseInt(company_data?.ordinary_shares || "0") !== 0 ? (
                    <div className="col-md-2">
                      <span>Ordinary</span>
                      <h5>
                        {company_data.ordinary_shares === ""
                          ? "0"
                          : numberWithCommas(company_data.ordinary_shares)}
                      </h5>
                    </div>
                  ) : null} */}

                    {company_data?.redeemable_shares &&
                    parseInt(company_data?.redeemable_shares || "0") !== 0 ? (
                      <div className="col-md-2">
                        <span>Redeemable</span>
                        <h5
                          data-toggle="tooltip"
                          data-placement="top"
                          title={numberWithCommas(
                            company_data.redeemable_shares
                          )}
                          className="text-truncate w-100"
                        >
                          {company_data.redeemable_shares === ""
                            ? "0"
                            : numberWithCommas(company_data.redeemable_shares)}
                        </h5>
                      </div>
                    ) : null}

                    {company_data?.preference_shares &&
                    parseInt(company_data?.preference_shares || "0") !== 0 ? (
                      <div className="col-md-2">
                        <span>Preference</span>
                        <h5
                          data-toggle="tooltip"
                          data-placement="top"
                          title={numberWithCommas(
                            company_data.preference_shares
                          )}
                          className="text-truncate w-100"
                        >
                          {company_data.preference_shares === ""
                            ? "0"
                            : numberWithCommas(company_data.preference_shares)}
                        </h5>
                      </div>
                    ) : null}

                    {company_data?.non_voting_shares &&
                    parseInt(company_data?.non_voting_shares || "0") !== 0 ? (
                      <div className="col-md-2">
                        <span>Non-Voting</span>
                        <h5
                          data-toggle="tooltip"
                          data-placement="top"
                          title={numberWithCommas(
                            company_data.non_voting_shares
                          )}
                          className="text-truncate w-100"
                        >
                          {company_data.non_voting_shares === ""
                            ? "0"
                            : numberWithCommas(company_data.non_voting_shares)}
                        </h5>
                      </div>
                    ) : null}

                    {company_data?.management_shares &&
                    parseInt(company_data?.management_shares || "0") !== 0 ? (
                      <div className="col-md-2">
                        <span>ESOS</span>
                        <h5
                          data-toggle="tooltip"
                          data-placement="top"
                          title={numberWithCommas(
                            company_data.management_shares
                          )}
                          className="text-truncate w-100"
                        >
                          {company_data.management_shares === ""
                            ? "0"
                            : numberWithCommas(company_data.management_shares)}
                        </h5>
                      </div>
                    ) : null}
                  </div>
                </ShareWrapperForResponsive>

                <div className="row mt-3">
                  {!graphDataLoading && graphData?.length > 1 && (
                    <div className="col-md-4 mt-2">
                      <ScrollWrapper
                        className="card shadow"
                        style={{
                          borderRadius: "20px",
                          maxHeight: "405px",
                          minHeight: "405px",
                          overflowY: "scroll",
                          overflowX: "hidden",
                        }}
                      >
                        <div
                          className="text-center d-flex mt-2 ml-3"
                          style={{ gap: "20px" }}
                        >
                          <FaChartPie className="text-muted" size={40} />
                          <h5 className="mt-2 font-weight-bold">
                            {" "}
                            {/* Shareholder Types */}
                            Categories of Shareholding
                          </h5>
                        </div>
                        {!graphDataLoading && graphData.length > 1 ? (
                          <>
                            <div
                              // style={{ paddingBottom: "20px" }}
                              className="d-flex justify-content-center align-items-center"
                            >
                              <Chart
                                chartType="PieChart"
                                data={graphData}
                                options={options}
                                style={{
                                  borderRadius: "2rem",
                                  overflow: "hidden",
                                  // paddingLeft: '10px',
                                  // paddingBottom: "20px",
                                }}
                              />
                            </div>
                            <div className="row">
                              <div className="col-md-12" />
                            </div>
                            <ScrollWrapper
                              style={{
                                maxHeight: "120px",
                                minHeight: "120px",
                                overflowY: "scroll",
                                overflowX: "hidden",
                              }}
                            >
                              <Responsive className="col-12 px-0 d-flex justify-content-center">
                                {/* <div className="col-md-3" /> */}
                                <div
                                  style={{ width: "fit-content" }}
                                  className="ml-2 d-flex flex-column align-content-center justify-content-center align-items-start"
                                >
                                  {graphData
                                    ?.slice(1, graphData?.length)
                                    ?.map((ite, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="d-flex text-start"
                                          style={{
                                            alignItems: "center",
                                            gap: "5px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              minWidth: "10px",
                                              height: "10px",
                                              backgroundColor:
                                                dummyChartColor[index],
                                              maxWidth: "10px",
                                            }}
                                          ></div>
                                          <div>
                                            {ite[0]
                                              ?.toLowerCase()
                                              ?.split(" ")
                                              ?.map(
                                                (word) =>
                                                  word
                                                    .charAt(0)
                                                    ?.toUpperCase() +
                                                  word.slice(1)
                                              )
                                              ?.join(" ")
                                              ?.split(":")[0] +
                                              ": " +
                                              `${(
                                                (ite[1] / totalShares) *
                                                100
                                              ).toFixed(4)}`}
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                                {/* <div className="col-md-4"/> */}
                              </Responsive>
                            </ScrollWrapper>
                          </>
                        ) : graphDataLoading ? (
                          <Spinner />
                        ) : (
                          !graphData?.length && (
                            <div className="text-center">Data not Found</div>
                          )
                        )}
                      </ScrollWrapper>
                    </div>
                  )}

                  {boardDirectors && boardDirectors?.length > 0 && (
                    <div className="col-md-4 mt-2">
                      <ScrollWrapper
                        isOverflow={showCompanyGoveranceFullData}
                        className="card shadow"
                        style={{
                          borderRadius: "20px",
                          height: "405px",
                          minHeight: "400px",
                          overflowY: "scroll",
                          overflowX: "hidden",
                        }}
                      >
                        <div
                          className="text-center d-flex mt-2 ml-3"
                          style={{ gap: "20px" }}
                        >
                          {/* <FontAwesomeIcon className='text-muted' icon={faPeopleCarryBox} style={{ fontSize: '40px' }} /> */}
                          <h5 className="font-weight-bold">
                            Board of Directors
                          </h5>
                        </div>

                        {!company_data_loading ? (
                          <>
                            {/* {company_governance.length > 0 ? ( */}
                            {boardDirectors.length > 0 ? (
                              <div>
                                <div className="card-body">
                                  <TableWrapper className="table table-borderless">
                                    <tbody>
                                      {
                                        // IsJsonString(company_governance)
                                        //   ? JSON.parse(company_governance)?.map(
                                        boardDirectors?.map((item, index) => {
                                          // if(item.)
                                          return (
                                            <tr
                                              className={
                                                index ==
                                                // company_governance?.length - 1
                                                boardDirectors?.length - 1
                                                  ? ""
                                                  : "border-bottom"
                                              }
                                            >
                                              <td>
                                                <h1 className="text-muted">
                                                  {index + 1}
                                                </h1>
                                              </td>
                                              <td>
                                                <h6 className="m-0 p-0">
                                                  {item.name}
                                                </h6>
                                                {item.role && (
                                                  <span>{item.role}</span>
                                                )}
                                              </td>
                                            </tr>
                                          );
                                        })
                                        // : company_governance?.map(
                                        //     (item, index) => {
                                        //       return (
                                        //         <tr
                                        //           className={
                                        //             index ==
                                        //             company_governance?.length - 1
                                        //               ? ""
                                        //               : "border-bottom"
                                        //           }
                                        //         >
                                        //           <td>
                                        //             <h1 className="text-muted">
                                        //               {index + 1}
                                        //             </h1>
                                        //           </td>
                                        //           <td>
                                        //             <h6>{item.name}</h6>
                                        //             {item.role && (
                                        //               <span>{item.role}</span>
                                        //             )}
                                        //           </td>
                                        //         </tr>
                                        //       );
                                        //     }
                                        //   )
                                        // : company_governance.slice(0, 5).map((item, index) => {
                                        //   return (
                                        //     <tr class="border-bottom">
                                        //       <td><h1 className="text-muted">{index + 1}</h1></td>
                                        //       <td><h6 style={{fontWeight: 'bold'}}>{item.name}</h6>
                                        //         {item.role && <span>{item.role}</span>}
                                        //       </td>
                                        //     </tr>
                                        //   );
                                        // })
                                      }
                                      {/* <tr>
                          <td><h1 className="text-muted">2</h1></td>
                          <td><h6>Ali Imran Hashmi</h6>
                            <span>Shareholder Director</span>
                          </td>
                        </tr>
                        <tr>
                          <td><h1 className="text-muted">3</h1></td>
                          <td><h6>Muhammad Kamran</h6>
                          <span>Nominee Director</span>
                          </td>
                        </tr>
                        <tr>
                          <td><h1 className="text-muted">4</h1></td>
                          <td><h6>Israr Danyal</h6>
                          <span>Shareholder Director</span>
                          </td>
                        </tr> */}
                                    </tbody>
                                  </TableWrapper>
                                  {/* {showCompanyGoveranceFullData ? <FaAngleUpWrapper onClick={() => setShowCompanyGoveranceData(false)} className='text-secondary' /> : <FaAngleDownWrapper onClick={() => setShowCompanyGoveranceData(true)} className='text-secondary' />} */}
                                </div>
                              </div>
                            ) : (
                              <div className="text-center">Data not Found</div>
                            )}
                          </>
                        ) : (
                          <Spinner />
                        )}
                      </ScrollWrapper>
                    </div>
                  )}

                  {keyExective && keyExective?.length > 0 && (
                    <div className="col-md-4 mt-2">
                      <div
                        className="card shadow"
                        style={{ borderRadius: "20px", minHeight: "405px" }}
                      >
                        <div
                          className="text-center d-flex mt-2 ml-3"
                          style={{ gap: "20px" }}
                        >
                          {/* <Key size={40} /> */}
                          {/* <FontAwesomeIcon className='text-muted' icon={faPeopleGroup} style={{ fontSize: '40px' }} /> */}
                          <h5 className="font-weight-bold">Key Executives</h5>
                        </div>
                        {!company_data_loading ? (
                          <>
                            {keyExective?.length ? (
                              <div>
                                <div className="card-body">
                                  <TableWrapperKeyExective className="table table-borderless">
                                    <tbody>
                                      {keyExective?.map((item, idx) => {
                                        return (
                                          <tr
                                            key={idx}
                                            className={
                                              idx == keyExective?.length - 1
                                                ? ""
                                                : "border-bottom"
                                            }
                                          >
                                            {/* <td className="pb-2"> */}
                                            <td>
                                              <h1 className="text-muted">
                                                {idx + 1}
                                              </h1>
                                            </td>
                                            {/* <td className="pb-2"> */}
                                            <td>
                                              {/* <h6 style={{ fontWeight: "bold" }} className="m-0 p-0"> */}
                                              <h6 className="m-0 p-0">
                                                {item?.name}
                                              </h6>
                                              {item.role && (
                                                <span>{item.role}</span>
                                              )}
                                            </td>
                                            {/* <td><h6 style={{ fontWeight: 'bold' }}>{item.name}</h6>
                                  {item.role && <span>{item.role}</span>}
                                </td> */}
                                          </tr>
                                        );
                                      })}

                                      {/* {companyChairman ?
                            <tr className="border-bottom">
                              <td><h1 className="text-muted">1</h1></td>
                              <td>
                                <h6 style={{fontWeight: 'bold'}}>Chairman</h6>
                                <span> {companyChairman}</span>
                              </td>
                            </tr>
                            : ''}
                          {ceoName || company_data.ceo_name ?
                            <tr className="border-bottom">
                              <td><h1 className="text-muted">{companyChairman ? 2 : 1}</h1></td>
                              <td>
                                <h6 style={{fontWeight: 'bold'}}>CEO</h6>
                                <span>{ ceoName || company_data.ceo_name}</span>
                              </td>
                            </tr> : ''}
                          {companyCFO ?
                            <tr className="border-bottom">
                              <td><h1 className="text-muted">{companyChairman && (ceoName || company_data.ceo_name) ? 3 : ceoName || company_data.ceo_name || companyChairman ? 2 : 1}</h1></td>
                              <td>
                                <h6 style={{fontWeight: 'bold'}}>CFO</h6>
                                <span>{companyCFO}</span>
                              </td>
                            </tr> : ''}
                          {company_secretary || company_data.company_secretary ?
                            <tr>
                              <td><h1 className="text-muted">{(companyChairman && (ceoName || company_data.ceo_name) && companyCFO) ? 4 : (companyChairman && (company_data.ceo_name ||ceoName)) ? 3 : (companyChairman && companyCFO) ? 3 : ((company_data.ceo_name || ceoName) && companyCFO) ? 3 : (ceoName || company_data.ceo_name || companyCFO || companyChairman) ? 2 : 1}</h1></td>
                              <td>
                                <h6 style={{fontWeight: 'bold'}}>Company Secretary</h6>
                                <span>{company_secretary || company_data.company_secretary}</span>
                              </td>
                            </tr> : ''} */}
                                      {/* <tr>
                          <td><h1 className="text-muted">2</h1></td>
                          <td><h6>Muhammad Ali Nasir</h6>
                            <span>Company Secretary</span>
                          </td>
                        </tr>
                        <tr>
                          <td><h1 className="text-muted">3</h1></td>
                          <td><h6>Muhammad Nawaz</h6>
                          <span>Chief Finance Officer</span>
                          </td>
                        </tr>
                        <tr>
                          <td><h1 className="text-muted">4</h1></td>
                          <td><h6>Shahbaz Ali Agha</h6>
                          <span>Chief Operating Officer</span>
                          </td>
                        </tr> */}
                                    </tbody>
                                  </TableWrapperKeyExective>
                                </div>
                              </div>
                            ) : (
                              <div className="text-center">Data not Found</div>
                            )}
                          </>
                        ) : (
                          <Spinner />
                        )}
                      </div>
                    </div>
                  )}
                  {/* calander card hidden */}
                  {/* <div className="col-md-4">
          <div className="card h-100 shadow"
          >
            <div className="card-header">
              <h5><Clock /> Events</h5>
            </div>
            <div className="card-body">
              {!announcement_data_loading ? (
                announcement_data.length > 0 ? (
                  <Calendar
                    localizer={localizer}
                    scrollToTime={new Date(1970, 1, 1, 6)}
                    defaultDate={new Date(2022, 3, 12)}
                    onSelectEvent={(event) => alert(event.title)}
                    views={allViews}
                    events={eventData}
                    eventOverlap
                    dragRevertDuration={500}
                    dragScroll
                    showMultiDayTimes
                    step={60}
                    startAccessor="start"
                    endAccessor="end"
                  />
                ) : (
                  <p>
                    <b>No Upcoming Events Available</b>
                  </p>
                )
              ) : (
                <Spinner />
              )}
            </div>
          </div>
        </div> */}
                  {/* calander card hidden */}
                </div>
                <WrapperForResponsive>
                  <div className="row mt-2 responsive">
                    {auditor || company_data?.company_auditor ? (
                      <div className="col-md-3 mt-2">
                        <div>
                          {" "}
                          <h3>
                            <Eye /> Auditors
                          </h3>
                          <h6 style={{ fontWeight: "bold" }}>
                            {auditor || company_data?.company_auditor}
                          </h6>
                          <span>External Auditor</span>
                        </div>
                      </div>
                    ) : null}

                    {company_data?.company_registrar ? (
                      <div className="col-md-3  mt-2">
                        <div>
                          {" "}
                          <h3>
                            <FaDatabase className="text-muted" size={30} />{" "}
                            Registrar
                          </h3>
                          <h6 style={{ fontWeight: "bold" }}>
                            {company_data?.company_registrar}
                          </h6>
                          <span> Share Registrar</span>
                        </div>
                      </div>
                    ) : null}
                    {company_data?.fiscal_year ? (
                      <div className="col-md-3  mt-2  ">
                        <div>
                          {" "}
                          <h3>
                            <FaCalendarAlt className="text-muted" size={30} />{" "}
                            {/* Fiscal Year Start */}
                            Fiscal Year
                          </h3>
                          <h6 style={{ fontWeight: "bold" }}>
                            {/* {company_data.fiscal_year} */}
                            {selectedCompanyData?.fiscal_year} -{" "}
                            {moment()
                              .month(selectedCompanyData?.fiscal_year)
                              .subtract(1, "months")
                              .format("MMMM")}
                          </h6>
                        </div>
                      </div>
                    ) : null}
                    {nextBoardElection ||
                    moment(boardElectionData.getFullYear() + 3).format(
                      "DD-MM-YYYY"
                    ) !== "Invalid date" ? (
                      <div className="col-md-3  mt-2  ">
                        <div>
                          {" "}
                          <h3>
                            <FaCalendarCheck className="text-muted" size={30} />{" "}
                            Board Election
                          </h3>
                          <h6 style={{ fontWeight: "bold" }}>
                            {" "}
                            Next :{" "}
                            {moment(nextBoardElection).format("DD-MMM-YYYY") ||
                              moment(
                                boardElectionData.setFullYear(
                                  boardElectionData.getFullYear() + 3
                                )
                              ).format("DD-MMM-YYYY")}
                          </h6>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </WrapperForResponsive>
              </div>

              <div className="container-fluid mb-3">
                <div className="row">
                  {announcement_data && announcement_data?.length > 0 && (
                    <div className="col mt-4">
                      <div
                        className="card  shadow"
                        style={{ borderRadius: "20px", minHeight: "370px" }}
                      >
                        <div
                          className="text-center d-flex mt-2 ml-3"
                          style={{ gap: "20px", alignItems: "center" }}
                        >
                          {/* <Key size={40} /> */}
                          {/* <img style={{marginTop: '-5px'}} src={corporate} alt="" /> */}
                          <AiFillNotification
                            className="text-muted"
                            size={30}
                          />
                          <h5 style={{ color: "#04b4fa" }}>
                            Corporate Actions
                          </h5>
                        </div>

                        {!company_data_loading ? (
                          <>
                            {announcement_data.length > 0 ? (
                              <div>
                                <div className="card-body">
                                  <TableWrapper className="table table-borderless">
                                    <tbody>
                                      {
                                        announcement_data.map((item, index) => {
                                          return (
                                            <ResponsiveWrapperForTable
                                              className={
                                                index ==
                                                announcement_data?.length - 1
                                                  ? ""
                                                  : "border-bottom"
                                              }
                                            >
                                              <td
                                                style={{
                                                  color: "rgb(4, 180, 250)",
                                                }}
                                                className="pb-2"
                                              >
                                                <div
                                                  style={{
                                                    fontWeight: "bold",
                                                    fontSize: "20px",
                                                  }}
                                                >
                                                  {item?.announcement_date !==
                                                    "" &&
                                                    moment(
                                                      item.announcement_date
                                                    ).format("DD")}
                                                </div>
                                                <div
                                                  style={{
                                                    fontWeight: "bold",
                                                    marginTop: "-8px",
                                                    alignItems: "center",
                                                    fontSize: "14px",
                                                  }}
                                                >
                                                  {item?.announcement_date !==
                                                    "" &&
                                                    moment(
                                                      item.announcement_date
                                                    ).format("MMM")}
                                                </div>
                                              </td>
                                              <td
                                                className="pb-2"
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <h6
                                                  className="responsiveHeading"
                                                  style={{
                                                    fontWeight: "bold",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    marginBottom: "0px",
                                                  }}
                                                >{`${
                                                  Number(item?.bonus_percent) >
                                                  0
                                                    ? "Bonus Entitlements"
                                                    : ""
                                                } ${
                                                  Number(
                                                    item?.dividend_percent
                                                  ) > 0
                                                    ? "Dividend Entitlements"
                                                    : ""
                                                }  ${
                                                  Number(item?.right_percent) >
                                                  0
                                                    ? "Right Entitlements"
                                                    : ""
                                                }`}</h6>
                                                {item.expired == "true" ? (
                                                  <span className="text-success mt-0">
                                                    COMPLETED
                                                  </span>
                                                ) : (
                                                  <span className="text-warning mt-0">
                                                    IN PROCESS
                                                  </span>
                                                )}
                                              </td>
                                              <td className="pb-2">
                                                <div
                                                  style={{
                                                    fontWeight: "bold",
                                                    fontSize: "20px",
                                                  }}
                                                  className="text-warning"
                                                >
                                                  {`${
                                                    item?.bonus_allotment_date
                                                      ? // ? "Bonous Entitlements"
                                                        moment(
                                                          item.bonus_allotment_date
                                                        ).format("DD")
                                                      : item?.right_allotment_date
                                                      ? moment(
                                                          item.right_allotment_date
                                                        ).format("DD")
                                                      : ""
                                                  }`}
                                                </div>
                                                <div
                                                  style={{
                                                    fontWeight: "bold",
                                                    marginTop: "-8px",
                                                    alignItems: "center",
                                                    fontSize: "14px",
                                                  }}
                                                  className="text-warning"
                                                >
                                                  {`${
                                                    item?.bonus_allotment_date
                                                      ? // ? "Bonous Entitlements"
                                                        moment(
                                                          item.bonus_allotment_date
                                                        ).format("MMM")
                                                      : item?.right_allotment_date
                                                      ? moment(
                                                          item.right_allotment_date
                                                        ).format("MMM")
                                                      : ""
                                                  }`}
                                                </div>
                                              </td>
                                            </ResponsiveWrapperForTable>
                                          );
                                        })
                                        // : announcement_data.slice(0, 4).map((item, index) => {
                                        //   return (
                                        //     <tr className="border-bottom">
                                        //       <td><h6 style={{ fontWeight: 'bold' }} className="text-primary">{moment(item.announcement_date).format('DD MMM')}</h6></td>
                                        //       <td><h6 style={{ fontWeight: 'bold' }}>{item.symbol}</h6><span style={{ fontWeight: 'bold' }}>{item.company_code}</span></td>
                                        //       <td><h6 style={{ fontWeight: 'bold' }} className="text-warning">{item.period_ended !== "" && moment(item.period_ended).format('DD MMM')}</h6></td>
                                        //     </tr>
                                        //   );
                                        // })
                                      }

                                      {/* <tr>
                        <td><h6 className="text-primary">05 Feb</h6></td>
                          <td><h6>Dividend Disbursement</h6><span>In Process</span></td>
                          <td><h6 className="text-warning">15 Feb</h6></td>
                        </tr>
                        <tr>
                          <td><h6 className="text-primary">13 Feb</h6></td>
                          <td><h6>Bonus Entitlements</h6><span>Completed</span></td>
                          <td><h6 className="text-warning">25 Feb</h6></td>
                        </tr>
                        <tr>
                        <td><h6 className="text-primary">01 Mar</h6></td>
                          <td><h6>Right Entitlements</h6><span>Completed</span></td>
                          <td><h6 className="text-warning">21 Apr</h6></td>
                        </tr> */}
                                    </tbody>
                                  </TableWrapper>
                                  {/* {announcement_data.length > 4 ? showFullAnnouncement ? <FaAngleUpWrapper onClick={() => setshowFullAnnouncment(false)} className='text-secondary' /> : <FaAngleDownWrapper onClick={() => setshowFullAnnouncment(true)} className='text-secondary' /> : ''} */}
                                </div>
                              </div>
                            ) : (
                              <div className="text-center">Data not Found</div>
                            )}
                          </>
                        ) : (
                          <Spinner />
                        )}
                      </div>
                    </div>
                  )}

                  {investorRequest && investorRequest.length > 0 && (
                    <div className="col mt-4">
                      <div
                        className="card  shadow "
                        style={{ borderRadius: "20px", minHeight: "370px" }}
                      >
                        <div
                          className="text-center d-flex mt-2 ml-3"
                          style={{ gap: "20px" }}
                        >
                          {/* <Key size={40} /> */}
                          <Send className="text-muted" size={30} />
                          <h5 style={{ color: "#04b4fa" }}>
                            {" "}
                            Investor Requests
                          </h5>
                        </div>
                        {!transaction_request_data_loading ? (
                          <>
                            {investorRequest?.length ? (
                              <div>
                                <div
                                  className="card-body"
                                  style={{ height: "fit-content" }}
                                >
                                  <TableWrapper className="table table-borderless">
                                    <tbody>
                                      {investorRequest.map((item, idx) => {
                                        return (
                                          <tr
                                            className={
                                              idx == investorRequest?.length - 1
                                                ? ""
                                                : "border-bottom"
                                            }
                                          >
                                            <td
                                              style={{
                                                color: "rgb(4, 180, 250)",
                                              }}
                                              className="pb-2"
                                            >
                                              <div
                                                style={{
                                                  fontWeight: "bold",
                                                  fontSize: "20px",
                                                }}
                                              >
                                                {item?.txn_date
                                                  ? moment(
                                                      item.txn_date
                                                    ).format("DD")
                                                  : ""}
                                              </div>
                                              <div
                                                style={{
                                                  fontWeight: "bold",
                                                  marginTop: "-8px",
                                                  fontSize: "14px",
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                {item?.txn_date
                                                  ? moment(
                                                      item.txn_date
                                                    ).format("MMM")
                                                  : ""}
                                              </div>
                                            </td>
                                            <td className="pb-2">
                                              <h6
                                                className="p-0 m-0"
                                                style={{ fontWeight: "bold" }}
                                              >
                                                {
                                                  transaction_request_types.find(
                                                    (tem) =>
                                                      tem.value ===
                                                      item.request_type
                                                  )?.label
                                                }
                                              </h6>

                                              {item.status?.toLowerCase() ==
                                              "pending" ? (
                                                <span className="text-warning mt-0">
                                                  {item.status}
                                                </span>
                                              ) : (
                                                <span className=" text-danger mt-0">
                                                  {item.status}
                                                </span>
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </TableWrapper>
                                </div>
                              </div>
                            ) : (
                              <div className="text-center">Data not Found</div>
                            )}
                          </>
                        ) : (
                          <Spinner />
                        )}
                      </div>
                    </div>
                  )}

                  {completedTxns && completedTxns.length > 0 && (
                    <div className="col mt-4">
                      <div
                        className="card  shadow"
                        style={{ borderRadius: "20px", minHeight: "370px" }}
                      >
                        <div
                          className="text-center d-flex mt-2 ml-3"
                          style={{ gap: "20px" }}
                        >
                          <BsCheckLg className="text-muted  ms-3" size={30} />
                          <h5 style={{ color: "#04b4fa" }}>
                            {" "}
                            Completed Requests
                          </h5>
                        </div>
                        {!transaction_request_data_loading ? (
                          <>
                            {" "}
                            {completedTxns?.length ? (
                              <div>
                                <div
                                  className="card-body"
                                  style={{ height: "fit-content" }}
                                >
                                  <TableWrapper className="table table-borderless">
                                    <tbody>
                                      {
                                        completedTxns.map((item, idx) => {
                                          return (
                                            <tr
                                              className={
                                                idx == completedTxns?.length - 1
                                                  ? ""
                                                  : "border-bottom"
                                              }
                                            >
                                              <td
                                                style={{
                                                  color: "rgb(4, 180, 250)",
                                                }}
                                                className="pb-2"
                                              >
                                                <div
                                                  style={{
                                                    fontWeight: "bold",
                                                    fontSize: "20px",
                                                  }}
                                                >
                                                  {item?.request_date
                                                    ? moment(
                                                        item.request_date
                                                      ).format("DD")
                                                    : ""}
                                                </div>
                                                <div
                                                  style={{
                                                    fontWeight: "bold",
                                                    marginTop: "-8px",
                                                    alignItems: "center",
                                                    fontSize: "14px",
                                                  }}
                                                >
                                                  {item?.request_date
                                                    ? moment(
                                                        item.request_date
                                                      ).format("MMM")
                                                    : ""}
                                                </div>
                                              </td>
                                              <td className="pb-2">
                                                <h6
                                                  style={{ fontWeight: "bold" }}
                                                >
                                                  {
                                                    transaction_request_types.find(
                                                      (tem) =>
                                                        tem.value ===
                                                        item.request_type
                                                    )?.label
                                                  }
                                                </h6>
                                                <span
                                                  style={{ fontWeight: "bold" }}
                                                >
                                                  {item.processing_status}
                                                </span>
                                              </td>
                                              <td className="pb-2">
                                                <div
                                                  style={{
                                                    fontWeight: "bold",
                                                    fontSize: "20px",
                                                  }}
                                                  className="text-warning"
                                                >
                                                  {item?.txn_execution_date
                                                    ? moment(
                                                        item.txn_execution_date
                                                      ).format("DD")
                                                    : ""}
                                                </div>
                                                <div
                                                  style={{
                                                    fontWeight: "bold",
                                                    marginTop: "-8px",
                                                    alignItems: "center",
                                                    fontSize: "14px",
                                                  }}
                                                  className="text-warning"
                                                >
                                                  {item?.txn_execution_date
                                                    ? moment(
                                                        item.txn_execution_date
                                                      ).format("MMM")
                                                    : ""}
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                        // : completedTxns.slice(0, 4).map((item) => {
                                        //   return (
                                        //     <tr className="border-bottom">
                                        //       <td><h6 style={{ fontWeight: 'bold' }} className="text-primary">{moment(item.txn_date).format('DD MMM')}</h6></td>
                                        //       <td><h6 style={{ fontWeight: 'bold' }}>
                                        //         {
                                        //           transaction_request_types.find(
                                        //             (tem) => tem.value === item.txn_type
                                        //           )?.label
                                        //         }
                                        //       </h6><span style={{ fontWeight: 'bold' }}>{item.processing_status}</span></td>
                                        //       <td><h6 style={{ fontWeight: 'bold' }} className="text-warning">{moment(item.settlement_date).format('DD MMM')}</h6></td>
                                        //     </tr>
                                        //   );
                                        // })
                                      }
                                      {/* <tr>
                        <td><h6 className="text-primary">05 Feb</h6></td>
                          <td><h6>Verify Trnasfer Deed</h6><span>Completed</span></td>
                          <td><h6 className="text-warning">15 Feb</h6></td>
                        </tr>
                        <tr>
                          <td><h6 className="text-primary">13 Feb</h6></td>
                          <td><h6>Split Share Certificate</h6><span>Completed</span></td>
                          <td><h6 className="text-warning">25 Feb</h6></td>
                        </tr>
                        <tr>
                        <td><h6 className="text-primary">01 Mar</h6></td>
                          <td><h6>Share Submission</h6><span>Completed</span></td>
                          <td><h6 className="text-warning">21 Apr</h6></td>
                        </tr> */}
                                    </tbody>
                                  </TableWrapper>
                                  {/* {completedTxns.length> 4 ?  showCompletedTxns ? <FaAngleUpWrapper onClick={() => setShowCompletedTxns(false)} className='text-secondary' /> : <FaAngleDownWrapper onClick={() => setShowCompletedTxns(true)} className='text-secondary' /> : ''} */}
                                </div>
                              </div>
                            ) : (
                              <div className="text-center">Data not Found</div>
                            )}
                          </>
                        ) : (
                          <Spinner />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {!loading && eventData && eventData.length > 0 ? (
                <div className="container-fluid mb-3 mt-3">
                  <div className="row">
                    <div className="col"></div>
                    <div className="col-lg-6 col-md-12 mt-3">
                      <div className="card">
                        <div
                          className="card-header d-flex flex-wrap justify-content-between mt-2 ml-0"
                          style={{ gap: "20px" }}
                        >
                          <div className="d-flex justify-content-start align-items-center align-content-center">
                            <FaCalendarAlt className="text-muted" size={30} />
                            <h5
                              style={{
                                color: "#04b4fa",
                                margin: "0px",
                                padding: "0px",
                                marginLeft: "0.25rem",
                              }}
                            >
                              Upcoming Events
                            </h5>
                          </div>
                        </div>
                        {!loading ? (
                          <>
                            <StyleUpcomingEventCard>
                              <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center">
                                  {/* Left arrow */}
                                  <ArrowButton
                                    className="d-flex"
                                    onClick={handleScrollLeft}
                                  >
                                    <FaChevronLeft />
                                  </ArrowButton>

                                  {/* Calendar cards */}
                                  <div
                                    className="calendar-scroll-container"
                                    style={{
                                      display: "flex",
                                      gap: screen <= 500 ? "5px" : "20px",
                                      overflowX: "hidden",
                                      flexWrap: "nowrap",
                                      scrollBehavior: "smooth", // Enable smooth scrolling
                                    }}
                                    ref={scrollRef}
                                  >
                                    {eventData.map((item, index) => (
                                      <CalendarCard
                                        style={{
                                          minWidth:
                                            screen <= 500 ? "143px" : "",
                                          maxWidth:
                                            screen <= 500 ? "143px" : "",
                                        }}
                                        key={index}
                                        onClick={() => {
                                          sessionStorage.setItem(
                                            "selectedEvent",
                                            JSON.stringify(item)
                                          );
                                          setViewEvent(true);
                                        }}
                                      >
                                        <DayInfo>
                                          <span>
                                            {moment(item?.start_date).format(
                                              "ddd"
                                            )}
                                          </span>
                                          <DayNumber>
                                            {moment(item?.start_date).format(
                                              "DD"
                                            )}
                                          </DayNumber>
                                        </DayInfo>

                                        <MonthYear>
                                          <div>
                                            {moment(item?.start_date).format(
                                              "MMM"
                                            )}
                                          </div>
                                          <div>
                                            {moment(item?.start_date).format(
                                              "YYYY"
                                            )}
                                          </div>
                                        </MonthYear>
                                      </CalendarCard>
                                    ))}
                                  </div>

                                  {/* Right arrow */}
                                  <ArrowButton
                                    className="d-flex"
                                    onClick={handleScrollRight}
                                  >
                                    <FaChevronRight />
                                  </ArrowButton>
                                </div>
                              </div>
                            </StyleUpcomingEventCard>
                            <div className="card-footer w-100 d-flex text-center justify-content-center">
                              <button
                                className="btn btn-primary"
                                onClick={() => {
                                  history.push("/event-calendar");
                                }}
                              >
                                Show More
                              </button>
                            </div>
                          </>
                        ) : (
                          <Spinner />
                        )}
                      </div>
                    </div>

                    <div className="col"></div>
                  </div>
                </div>
              ) : null}
            </>
          )}
        </div>
      ) : !companySelected && !loading ? (
        <div
          className="d-flex justify-content-center align-content-center align-items-center"
          style={{ height: "50vh" }}
        >
          <h5>Select company from header to generate data.</h5>
        </div>
      ) : (
        loading && (
          <>
            <img alt="dcc_loader" src={loader} />
          </>
        )
      )}
    </>
  );
};

export default AdminDashboard;

const WrapperSharesHeader = styled.div`
  @media (max-width: 500px) {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
  }
`;
const WrapperHeader = styled.div`
  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .image-margin-onSmall {
      margin-left: 0px !important;
    }
  }
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WrapperForResponsive = styled.div`
  @media (max-width: 765px) {
    .responsive {
      width: 350px;
      margin: auto;
    }
  }
  @media (max-width: 500px) {
    .responsive {
      width: 250px;
      margin: auto;
    }
    .onSmall {
      // display: flex;
      // flex-direction: column;
      // align-items: center;
      // justify-content: center;
      text-align: center;
    }
  }
`;

const ShareWrapperForResponsive = styled.div`
  .responsive {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: auto;
  }

  .col-md-2 {
    flex: 1 1 12%; /* Allow for more items to fit in a row */
    max-width: 12%;
    text-align: center;
  }

  @media (max-width: 1200px) {
    .col-md-2 {
      flex: 1 1 20%; /* 5 items per row on large screens */
      max-width: 20%;
    }
  }

  @media (max-width: 992px) {
    .col-md-2 {
      flex: 1 1 30%; /* 3 items per row on medium screens */
      max-width: 30%;
    }
  }

  @media (max-width: 768px) {
    .col-md-2 {
      flex: 1 1 45%; /* 2 items per row on tablets */
      max-width: 45%;
    }
  }

  @media (max-width: 576px) {
    .col-md-2 {
      flex: 1 1 100%; /* 1 item per row on small screens */
      max-width: 100%;
    }
    .onSmall {
      text-align: center;
    }
  }
`;

const Responsive = styled.div`
  // @media(max-width: 1100px){
  //   .respons{
  //     margin-top: 10px;
  //       }
  // }
  @media (max-width: 1120px) {
    .respons {
      margin-left: 30px;
    }
  }
  @media (max-width: 765px) {
    .respons {
      margin-left: 200px;
    }
  }
  @media (max-width: 610px) {
    .respons {
      margin-left: 30px;
    }
  }
`;
const EventsWrapper = styled.div`
  // overflow-y: scroll;
  ::-webkit-scrollbar {
    height: 5px;
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    background: #f9f9fb;
  }
  ::-webkit-scrollbar-thumb {
    background: #4e515680;
    border-radius: 5px;
  }
`;
const ScrollWrapper = styled.div`
  // overflow-y: scroll;
  // height: 405px;
  ::-webkit-scrollbar {
    height: 5px;
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    background: #f9f9fb;
  }
  ::-webkit-scrollbar-thumb {
    background: #4e515680;
    border-radius: 5px;
  }
`;
const TableWrapper = styled.table`
  th,
  td {
    :nth-child(1) {
      padding-top: 10px;
      padding-bottom: 0px;
      padding-left: 0px;
      padding-right: 0px;
    }
    :nth-child(2) {
      padding-top: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
      padding-right: 0px;
    }
    :nth-child(3) {
      padding-top: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
      padding-right: 0px;
    }
  }
`;
const TableWrapperKeyExective = styled.table`
  tr td:nth-child(1) {
    padding-top: 10px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  tr td:nth-child(2) {
    padding-top: 10px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: -10px;
  }
`;
const ResponsiveWrapperForTable = styled.tr`
  @media (min-width: 100px) {
    .responsiveHeading {
      width: 100px;
    }
  }
  @media (min-width: 300px) {
    .responsiveHeading {
      width: 150px;
    }
  }
  @media (min-width: 400px) {
    .responsiveHeading {
      width: 200px;
    }
  }
  @media (min-width: 610px) {
    .responsiveHeading {
      width: 200px;
    }
  }
  @media (min-width: 1180px) {
    .responsiveHeading {
      width: 180px;
    }
  }
  @media (min-width: 1300px) {
    .responsiveHeading {
      width: 200px;
    }
  }
  @media (min-width: 1500px) {
    .responsiveHeading {
      width: 250px;
    }
  }
  @media (min-width: 1700px) {
    .responsiveHeading {
      width: 300px;
    }
  }
`;

// new events UI

// const CalendarContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 20px;
// `;

const CalendarCard = styled.div`
  background-color: #e0e0e0;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  width: 140px;
  height: 180px;
  margin: 0 15px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
`;

const MonthYear = styled.div`
  background-color: #003366;
  color: white;
  padding: 10px;
  border-radius: 6px;
  font-weight: bold;
  font-size: 16px;
`;

const DayInfo = styled.div`
  margin-top: 10px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DayNumber = styled.span`
  font-size: 32px;
  font-weight: bold;
  margin-top: 5px;
`;

const ArrowButton = styled.button`
  background: none;
  border: none;
  font-size: 32px;
  color: black;
  cursor: pointer;
  // margin: 0 20px;
`;
const StyleUpcomingEventCard = styled.div`
  .card-body {
    padding: 0.75rem 0rem !important;
  }
`;
